import {useEffect, useState} from "react";

// Patient Page Device Type
export const usePatientPageDeviceType = () => {
    const [viewType, setViewType] = useState<'desktop' | 'tablet'>('desktop');

    useEffect(() => {
        window.addEventListener('resize', checkScreenSize);

        checkScreenSize();

        return () => {
            window.removeEventListener('resize', checkScreenSize);
        }
    }, []);

    function checkScreenSize() {
        const width = window.innerWidth;
        if(width <= 1600){
            setViewType('tablet');
        } else {
            setViewType('desktop');
        }
    }

    return viewType;
};