import React, {FC, useContext, useState} from "react";
import {Col, Grid, Icon, iconsTypes, Row} from "lavaa";
import css from "./HealthBoxes.module.scss";
import {usePatientPageDeviceType} from "../../../../../Hooks/UsePatientPageDeviceType";
import {ModalsCtx} from "../../../../../Context/Modals.context";
import {MenuHealthBoxes} from "../../../../Custom/Modals/MenuHealthBoxes/MenuHealthBoxes.type";
import {HighRiskCard} from "../../HealthCard/Cards/HighRisk.com";
import {WorryScoreCard} from "../../HealthCard/Cards/WorryScore.com";
import {AttributionCard} from "../../HealthCard/Cards/Attribution.com";
import {ClinicalResearchCard} from "../../HealthCard/Cards/ClinicalResearch.com";
import {RafScoreCard} from "../../HealthCard/Cards/RafScore.com";
import {BmiCard} from "../../HealthCard/Cards/Bmi.com";
import {BloodPressureCard} from "../../HealthCard/Cards/BloodPressure.com";
import {PulseCard} from "../../HealthCard/Cards/Pulse.com";
import {HeightCard} from "../../HealthCard/Cards/Height.com";
import {WeightCard} from "../../HealthCard/Cards/Weight.com";
import {WaistCard} from "../../HealthCard/Cards/Waist.com";
import {RaceCard} from "../../HealthCard/Cards/Race.com";
import {ActivityCard} from "../../HealthCard/Cards/Activity.com";
import {StressCard} from "../../HealthCard/Cards/Stress.com";
import {TemperatureCard} from "../../HealthCard/Cards/Temperature.com";
import {CO2Card} from "../../HealthCard/Cards/Co2.com";
import {PainCard} from "../../HealthCard/Cards/Pain.com";
import {RespirationCard} from "../../HealthCard/Cards/Respiration.com";
import {A1CCard} from "../../HealthCard/Cards/A1C.com";
import {GFRCard} from "../../HealthCard/Cards/GFR.com";
import {LDLCard} from "../../HealthCard/Cards/LDL.com";
import {HospitalizationCard} from "../../HealthCard/Cards/Hospitalization.com";

type Props = {
	size: 'doubled' | 'full-width';
	onCardClick?: (cardName: string) => void;
}

const HealthBoxes: FC<Props> = React.memo((props) => {

	const { menuHealthBoxes } = useContext(ModalsCtx);

	const {size, onCardClick} = props;
	const viewType = usePatientPageDeviceType();
	const [page, setPage] = useState<number>(0);

	let maxBoxes = 7;

	if(viewType === 'desktop'){
		maxBoxes = size === 'doubled' ? 7 : 5;
	}
	else if(viewType === 'tablet'){
		maxBoxes = 3;
	}

	const healthBoxesComponents: any = {
		[MenuHealthBoxes.WorryScore]: <WorryScoreCard onCardClick={onCardClick} />,
		[MenuHealthBoxes.HighRisk]: <HighRiskCard onCardClick={onCardClick} />,
		// [MenuHealthBoxes.Attribution]: <AttributionCard/>,
		[MenuHealthBoxes.ClinicalResearch]: <ClinicalResearchCard onCardClick={onCardClick} />,
		[MenuHealthBoxes.RafScore]: <RafScoreCard onCardClick={onCardClick} />,
		[MenuHealthBoxes.BMI]: <BmiCard onCardClick={onCardClick} />,
		[MenuHealthBoxes.BloodPressure]: <BloodPressureCard onCardClick={onCardClick} />,
		[MenuHealthBoxes.Pulse]: <PulseCard onCardClick={onCardClick} />,
		[MenuHealthBoxes.Height]: <HeightCard onCardClick={onCardClick} />,
		[MenuHealthBoxes.Weight]: <WeightCard onCardClick={onCardClick} />,
		[MenuHealthBoxes.Waist]: <WaistCard onCardClick={onCardClick} />,
		[MenuHealthBoxes.Race]: <RaceCard onCardClick={onCardClick} />,
		[MenuHealthBoxes.Activity]: <ActivityCard onCardClick={onCardClick} />,
		[MenuHealthBoxes.Stress]: <StressCard onCardClick={onCardClick} />,
		[MenuHealthBoxes.Temperature]: <TemperatureCard onCardClick={onCardClick} />,
		[MenuHealthBoxes.CO2]: <CO2Card onCardClick={onCardClick} />,
		[MenuHealthBoxes.Pain]: <PainCard onCardClick={onCardClick} />,
		[MenuHealthBoxes.Respiration]: <RespirationCard onCardClick={onCardClick} />,
		// [MenuHealthBoxes.Hospitalization]: <HospitalizationCard/>,
		[MenuHealthBoxes.A1C]: <A1CCard onCardClick={onCardClick} />,
		[MenuHealthBoxes.GFR]: <GFRCard onCardClick={onCardClick} />,
		[MenuHealthBoxes.LDL]: <LDLCard onCardClick={onCardClick} />,
	}

	const hasArrows = menuHealthBoxes.length > maxBoxes;
	const prevDisabled = page === 0;
	const nextDisabled = (page + 1) * maxBoxes >= menuHealthBoxes.length;

	function prevPage(){
		if(prevDisabled) return;
		setPage(page - 1);
	}

	function nextPage(){
		if(nextDisabled) return;
		setPage(page + 1);
	}

	// Calculate slice range
	const sliceEnd = Math.min(maxBoxes * (page + 1), menuHealthBoxes.length);
	const sliceStart = Math.max(Math.min(maxBoxes * page, sliceEnd - maxBoxes), 0);

	return (
		<Grid data-type={size} className={css.Grid}>
			{
				menuHealthBoxes.slice(sliceStart, sliceEnd).map((item: MenuHealthBoxes, index: number) => {
					if(!healthBoxesComponents[item]) return null;

					return(
						<Col key={item}>
							{healthBoxesComponents[item]}
						</Col>
					)
				})
			}
			{
				hasArrows && (
					<Row alignitems="center" justifycontent="center" className={css.Arrows}>
						<Col className={css.Arrow} alignitems="center" justifycontent="center" data-disable={prevDisabled} onClick={prevPage}>
							<Icon name={iconsTypes.chevronLeft} size="x2"/>
						</Col>
						<Col className={css.Arrow} alignitems="center" justifycontent="center" data-disable={nextDisabled} onClick={nextPage}>
							<Icon name={iconsTypes.chevronRight} size="x2"/>
						</Col>
					</Row>
				)
			}
		</Grid>
	)
});

export {HealthBoxes};
