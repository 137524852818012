import React, {FC, useContext} from "react";
import {Button, Col, Icon, iconsTypes, Row, Text, Title, TooltipWrapper} from "lavaa";
import { useDemo } from "../../../../../Hooks/useDemo";
import { ModalsCtx } from "../../../../../Context/Modals.context";
import css from "./HealthBoxes.module.scss";

export type HealthBoxIndicatorColor = 'default-risk' | 'no-risk' | 'medium-risk' | 'medium-high-risk' | 'high-risk';

export type HealthBoxProps = {
	title: string;
	label: string;
	value: string;
	wsInfo: string;
	color?: HealthBoxIndicatorColor;
	cardName: string;
	onCardClick?: (cartName: string) => void;
}

const HealthBoxIndicator: FC<HealthBoxProps> = React.memo((props) => {

	// Props
	const { title, value, label, wsInfo, color, cardName, onCardClick } = props;
	
	// Hooks
	const { activeClient } = useDemo();

	// Context
	const {setReportClientInfoModalActive, setReportClientInfoId, setReportSourceClientInfoId} = useContext(ModalsCtx);
	
	// Vars
	const clientId = activeClient?.client?.SourceClientID;

	const openClientInfo = () => {
		console.log("openClientInfo", activeClient.clientID, clientId);
        setReportClientInfoId(activeClient.clientID);
        setReportSourceClientInfoId(clientId);
        setReportClientInfoModalActive(true);
	};

	return (
		<Col className={css.Box} justifycontent="space-between" grow="1" onClick={() => onCardClick && onCardClick(cardName)} data-clickable={!!onCardClick}>
			<Row alignitems="start">
				<Row grow="1">
					<Title size="x3">{title}</Title>
				</Row>

				{
					!value ? null : <Row paddingLeft="0.5rem">
						<TooltipWrapper tooltipText={wsInfo} positionV="top" positionH="right" whiteSpace="pre">
							<Icon size="x1" name={iconsTypes.infoOutlined} />
						</TooltipWrapper>
					</Row>
				}
			</Row>

			<Row alignitems="center" justifycontent="space-between">
				<Row alignitems="center" grow="1">
					<Col className={css.Indicator} data-color={color} marginRight="1rem"/>
					<Text size="x5" bold={true} ellipsis={true} className={css.Value}>{label}</Text>
				</Row>
				<Text size="x7" bold={true} ellipsis={true} className={css.Value}>{value}</Text>
				{
					value && (value.toString() === '9' || value.toString() === '10') 
					? <Row grow="1" paddingTop="0.5rem" justifycontent="end">
						<Button icon={iconsTypes.phone} type="primary" size="small" iconSize="16" className={css.UserInfoButton} onClick={openClientInfo}/>
					</Row> 
					: null
				}
			</Row>
		</Col>
	)
});

export {HealthBoxIndicator};
