interface ITheme {
    [key: string]: string;
}

const light: ITheme = {
    //Background Colors
    '--primary-bg-color': '#ffffff',
    '--secondary-bg-color': '#F5F6F8',
    '--danger-bg-color': '#FCE6EA',
    '--success-bg-color': '#EAF6E7',
    '--warning-bg-color': '#FFF3E0',
    '--popup-screen-bg-color': 'rgba(232, 232, 240, 0.5)',
    '--disable-bg-color': '#EDF0F4',
    '--neutral-bg-color': '#EAECF5',

    //Text Colors
    '--primary-text-color': '#2A2E43',
    '--secondary-text-color': '#495079',
    '--tertiary-text-color': '#888CA5',
    '--nodes-text-color': '#0C1E2A',
    '--disable-text-color': '#CDD3DB',
    '--danger-text-color': '#D13B51',
    '--success-text-color': '#208835',
    '--warning-text-color': '#F18D5D',

    //Icon Colors
    '--primary-bg-icons-color': '#3B54D0',
    '--secondary-bg-icons-color': '#6773A3',
    '--alternative-primary-icons-color': '#FFFFFF',
    '--alternative-secondary-icons-color': '#8F8FC1',
    '--success-icons-color': '#208835',
    '--warning-icons-color': '#F18D5D',
    '--danger-icons-color': '#D13B51',
    '--disable-icons-color': '#CDD3DB',

    //Alternative Colors
    '--alternative-primary-color': '#6773A3',
    '--alternative-secondary-bg-color': '#D9E0E7',
    '--alternative-primary-text-color': '#FFFFFF',
    '--alternative-secondary-text-color': '#C6CAF8',
    '--alternative-accent-text-color': '#3B54D0',

    //Sidebar Colors
    '--sidebar-primary-color': '#ffffff',
    '--sidebar-hover-color': '#EEEFF7',
    '--sidebar-primary-text-color': '#3B54D0',
    '--sidebar-text-color': '#A5B2C4',

    //Primary Button
    '--primary-button-bg-color': '#3B54D0',
    '--primary-button-text-color': '#FFFFFF',
    '--primary-button-hover-text-color': '#FFFFFF',
    '--primary-button-hover-color': '#2D46BD',
    '--primary-button-active-color': '#273DA5',

    //Secondary Button
    '--secondary-button-bg-color': '#FFFFFF',
    '--secondary-button-border-color': '#DEDDF2',
    '--secondary-button-hover-color': '#3D3BD0',
    '--secondary-button-hover-text-color': '#FFFFFF',
    '--secondary-button-active-color': '#2028AC',
    '--secondary-button-text-color': '#0C1E2A',

    //Ghost Button
    '--ghost-button-text-color': '#3B54D0',
    '--ghost-button-hover-color': '#2D46BD',
    '--ghost-button-active-color': '#273DA5',

    //Danger Button
    '--danger-button-bg-color': '#D13B51',
    '--danger-button-text-color': '#FFFFFF',
    '--danger-button-bg-hover-color': '#C8062A',
    '--danger-button-bg-active-color': '#B20625',

    // //Success Button
    '--success-button-bg-color': '#208835',
    '--success-button-text-color': '#FFFFFF',
    '--success-button-bg-hover-color': '#19772C',
    '--success-button-bg-active-color': '#0D6B20',

    //Warning Button
    '--warning-button-bg-color': '#F18D5D',
    '--warning-button-text-color': '#FFFFFF',
    '--warning-button-bg-hover-color': '#DA6D39',
    '--warning-button-bg-active-color': '#DE5D20',

    //Disable Button
    '--disable-button-bg-color': '#F5F6F8',
    '--disable-button-text-color': '#CDD3DB',
    '--disable-button-border-color': '#CDD3DB',

    //Risk colors
    '--high-risk': '#D13B51',
    '--medium-hight-risk': '#F18D5D',
    '--medium-risk': '#FDD835',
    '--medium-low-risk': '#07B951',
    '--no-risk': '#208835',
    '--default-risk': '#6F767E',

    //Borders
    '--primary-border-color': '#D13B51',
    '--main-border-color': '#EAEBF5',
    '--card-border-color': '#DEDDF2',

    //Background Images
    '--auth-background-image': 'url(/Images/auth_bg.jpg)',

    //Shadows
    '--hide-gradient': 'linear-gradient(180deg, rgba(255, 255, 255, 0), rgba(255, 255, 255, 1))',

    //Gradient Colors
    '--secondary-gradient-color': 'linear-gradient(180deg, #FFFFFF 0%, #F0F1F3 143.32%)',

    //Flow nodes Colors
    // '--additional-blue-light': '#BBDEFB',
    // '--additional-blue': '#2962FF',
    // '--additional-yellow-light': '#FFCC80',
    // '--additional-yellow': '#FFA726',
    // '--additional-violet-light': '#D3BAFF',
    // '--additional-violet': '#7C4DFF',
    // '--additional-green-light': '#B9F6CA',
    // '--additional-green': '#00E676',

    //Table
    '--odd-row-background-color': '#F9FAFB',
}

const dark: ITheme = {
    //Background Colors
    '--primary-bg-color': '#272B30',
    '--secondary-bg-color': '#202124', // prev: #111315
    '--danger-bg-color': '#382C2E',
    '--success-bg-color': '#364138',
    '--warning-bg-color': '#5A3905',
    '--popup-screen-bg-color': 'rgba(26, 29, 31, 0.5)',
    '--disable-bg-color': '#272B30',
    '--neutral-bg-color': '#404458',

    //Text Colors
    '--primary-text-color': '#e3e3e3',
    '--secondary-text-color': '#BDC2D8',
    '--tertiary-text-color': '#e3e3e3',
    '--nodes-text-color': '#111315',
    '--disable-text-color': '#585E64',
    '--danger-text-color': '#E40931',
    '--success-text-color': '#2AA40C',
    '--warning-text-color': '#FF9800',

    //Icon Colors
    '--primary-bg-icons-color': '#e3e3e3',
    '--secondary-bg-icons-color': '#A7AEB5',
    '--alternative-primary-icons-color': '#e3e3e3',
    '--alternative-secondary-icons-color': '#e3e3e3',
    '--success-icons-color': '#00C853',
    '--warning-icons-color': '#FF9800',
    '--danger-icons-color': '#E40931',
    '--disable-icons-color': '#585E64',

    //Alternative Colors
    '--alternative-primary-color': '#888CA5',
    '--alternative-secondary-bg-color': '#3B4046',
    '--alternative-primary-text-color': '#e3e3e3',
    '--alternative-secondary-text-color': '#919497',
    '--alternative-accent-text-color': '#e3e3e3',

    //Sidebar Colors
    '--sidebar-primary-color': '#1A1D1F',
    '--sidebar-hover-color': '#42454A',
    '--sidebar-primary-text-color': '#4985FA',
    '--sidebar-text-color': '#6F767E',

    //Primary Button
    '--primary-button-bg-color': '#4985FA',
    '--primary-button-text-color': '#e3e3e3',
    '--primary-button-hover-text-color': '#e3e3e3',
    '--primary-button-hover-color': '#3B54D0',
    '--primary-button-active-color': '#2D46BD',

    //Secondary Button
    '--secondary-button-bg-color': '#272B30',
    '--secondary-button-border-color': '#37485E',
    '--secondary-button-hover-color': '#4985FA',
    '--secondary-button-hover-text-color': '#e3e3e3',
    '--secondary-button-active-color': '#2832D1',
    '--secondary-button-text-color': '#e3e3e3',

    //Ghost Button
    '--ghost-button-text-color': '#4985FA',
    '--ghost-button-hover-color': '#2D46BD',
    '--ghost-button-active-color': '#273DA5',

    //Danger Button
    '--danger-button-bg-color': '#E40931',
    '--danger-button-text-color': '#e3e3e3',
    '--danger-button-bg-hover-color': '#C8062A',
    '--danger-button-bg-active-color': '#B20625',

    //Success Button
    '--success-button-bg-color': '#00C853',
    '--success-button-text-color': '#e3e3e3',
    '--success-button-bg-hover-color': '#02903D',
    '--success-button-bg-active-color': '#028337',

    //Warning Button
    '--warning-button-bg-color': '#FFA726',
    '--warning-button-text-color': '#e3e3e3',
    '--warning-button-bg-hover-color': '#FB8C00',
    '--warning-button-bg-active-color': '#F08600',

    //Disable Button
    '--disable-button-bg-color': '#393B3E',
    '--disable-button-text-color': '#585E64',
    '--disable-button-border-color': '#44474A',

    //Risk colors
    '--high-risk': '#FF3E1E',
    '--medium-hight-risk': '#FFA726',
    '--medium-risk': '#FDD835',
    '--medium-low-risk': '#00E676',
    '--no-risk': '#07B951',
    '--default-risk': '#6F767E',

    //Borders
    '--primary-border-color': '#D13B51',
    '--main-border-color': '#EAEBF5',
    '--card-border-color': '#e3e3e3',

    //Background Images
    '--auth-background-image': 'url(/Images/auth_bg_dark.jpg)',

    //Shadows
    '--hide-gradient': 'linear-gradient(180deg, rgba(39, 43, 48, 0), rgba(39, 43, 48, 1))',

    // //Gradient Colors
    '--secondary-gradient-color': 'linear-gradient(180deg, #23262F 1.84%, #413F42 153.8%)',

    // //Flow nodes Colors
    // '--additional-blue-light': '#BBDEFB',
    // '--additional-blue': '#2962FF',
    // '--additional-yellow-light': '#FFCC80',
    // '--additional-yellow': '#FFA726',
    // '--additional-violet-light': '#D3BAFF',
    // '--additional-violet': '#7C4DFF',
    // '--additional-green-light': '#B9F6CA',
    // '--additional-green': '#00E676',


    //Table
    '--odd-row-background-color': 'rgba(128, 128, 128, 0.1)'
}


const getThemeData = (theme: string): ITheme => {
    let data;
    switch (theme) {
        case 'light':
            data = light;
            break;
        case 'dark':
            data = dark;
            break;
        default:
            data = light;
            break;
    }
    return data;
};

export { getThemeData };