import { Cell, Row, Icon, iconsTypes } from 'lavaa';
import {MenuSearchDropdown} from './MenuSearchDropdown.component';
import {useContext, useState} from 'react';
import css from './MenuSearch.module.scss';
import {AppCtx} from "../../Context/App.context";
import {MenuSearchField} from "./MenuSearchField.com";

// Menu Profile
const MenuSearch = () => {

    let {deviceType} = useContext(AppCtx);

    //States
    const [dropdownActive, setDropdownActive] = useState(false);

    function clickHandler(){
        setDropdownActive((prev) => !prev);
    }

    if(deviceType !== "mobile"){
        return <MenuSearchField/>
    }

    return (
        <Row justifycontent="end" className={css.MenuSearchComponent}>
            <Row className={css.Wrapper}>
                <Row alignitems="center" className={css.Button} onClick={clickHandler}>
                    <Cell alignitems='center' justifycontent='center' className={css.Icon}>
                        <Icon name={iconsTypes.search}/>
                    </Cell>
                </Row>

                {
                    /* Dropdown */
                    dropdownActive && <MenuSearchDropdown closeHandler={() => setDropdownActive(false)}/>
                }
            </Row>
        </Row>
    );
};

export { MenuSearch };