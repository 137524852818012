import React, {FC} from "react";
import {iconsTypes} from "lavaa";
import {HealthBox, HealthBoxIconColor} from "../../Cards/HealthBoxes/HealthBox.com";
import {useDemo} from "../../../../../Hooks/useDemo";

interface IProps {
    onCardClick?: (cardName: string) => void;
}

// LDL Card
const LDLCard: FC<IProps> = React.memo((props) => {

    const { onCardClick } = props;
    const {activeClient} = useDemo();

    const title = "Last LDL";

    const labResults = activeClient?.client?.LabResults ? activeClient?.client?.LabResults[0] : null;
    const value = labResults?.LDLCholesterol ?? "-";

    let color:HealthBoxIconColor = "default";
    if(value >= 100){
        color = "danger";
    }
    else if(value < 100){
        color = "success";
    }

    return (
        <HealthBox
            title={title}
            icon={iconsTypes.labResults}
            value={value}
			iconColor={color}
            cardName="LastLDL"
            onCardClick={onCardClick}
        />
    )
});

export {LDLCard};
