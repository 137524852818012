import React, {FC} from "react";
import css from "./QuestionnaireSide.module.scss";
import {Col, PieChartDataType, Row, PieChart, Text} from "lavaa";
import {useQuestionnaire} from "../../../../../Hooks/UseQuestionnaire";
import {IQuestionnaireStatisticsType} from "../../../../../Redux/Slices/Questionnaire/Questionnaire.slice";
import {useDemo} from "../../../../../Hooks/useDemo";
import {IModuleType} from "../../../../../Redux/Slices/Demo/Demo.data";

interface IProps {}

const colors = [
    "#FFCC80", "#F6697E", "#07B951", "#00E676",
    "#BBDEFB", "#F18D5D", "#f6b9b9", "#7C4DFF",
    "#B9F6CA", "#e60049", "#B1BAF4", "#D3BAFF"
];


// Questionnaire Side
const QuestionnaireSide: FC<IProps> = React.memo(() => {

    const questionnaireStatistics: IQuestionnaireStatisticsType | undefined = useQuestionnaire().questionnaireStatistics;
    const { modules } = useDemo();

    if(!questionnaireStatistics) return null;

    const totalQuestions = questionnaireStatistics.QuestionsAmount;
    const totalAnswers = questionnaireStatistics.AnsweredQuestionsAmount;

    const completionRate = (totalQuestions === 0) ? 100 : Math.floor(totalAnswers / totalQuestions * 100);

    const completionRateChart: PieChartDataType[] = [
        {
            name: 'Answered',
            value: totalAnswers,
            color: "#3BD158"
        },
        {
            name: 'Unanswered',
            value: totalQuestions - totalAnswers,
            color: "#F6697E"
        },
    ];

    const byModulesChart: PieChartDataType[] = [];
    let modulesAddedCount = 0;
    let modulesAddedPercentage = 0;
    let hasSkipped = false;

    Object.entries(questionnaireStatistics.ModulesQuestionsPercentage).forEach(([key, value]: [string, number]) => {
        if(modulesAddedCount >= 9) {
            hasSkipped = true;
            return;
        }

        const module: IModuleType | undefined = modules.find((module: IModuleType) => module.ModuleUID === key);
        if(!module) {
            hasSkipped = true;
            return;
        }

        const percentage = Math.round(value * 10) / 10;

        byModulesChart.push({
            name: module.ModuleName,
            value: percentage,
            color: colors[modulesAddedCount % colors.length]
        });

        modulesAddedPercentage += percentage;
        modulesAddedCount++;
    });

    if(hasSkipped && modulesAddedPercentage < 100) {
        byModulesChart.push({
            name: 'Other',
            value: 100 - modulesAddedPercentage,
            color: colors[modulesAddedCount % colors.length]
        });
    }

    const estimatedTime = (totalQuestions - totalAnswers) * 0.5;

    return (
        <Col>
            <Col className={css.Side}>
                <Row className={css.BasicInfo} justifycontent="space-between" paddingTop="1rem">
                    <Col marginBottom="1rem">
                        <Text size="x3" bold="true">ESTIMATED COMPLETION RATE</Text>
                        <Text size="x3">{completionRate} % Completed</Text>
                    </Col>
                    {
                        estimatedTime >= 2 && (
                            <Col marginBottom="1rem">
                                <Text size="x3" bold="true">ESTIMATED TIME TO COMPLETE</Text>
                                <Text size="x3">{Math.ceil(estimatedTime)} Minutes</Text>
                            </Col>
                        )
                    }
                </Row>
                <Col className={css.ChartBox}>
                    <Col marginBottom="1rem">
                        <Text size="x4" bold="true">Questions by answers</Text>
                    </Col>
                    <Row grow="1" className={css.Chart} style={{height: '165px'}}>
                        <PieChart
                            data={completionRateChart}
                            textTitle="Total answers"
                            textValue={totalAnswers.toString()}
                            filled={false}
                            legendAlign='right'
                            legendVerticalAlign='middle'
                            chartPosition='left'
                            hasChartMargin={false}
                        />
                    </Row>
                </Col>
                <Col className={css.ChartBox}>
                    <Col marginBottom="1rem">
                        <Text size="x4" bold="true">Questions by modules</Text>
                    </Col>
                    <Row grow="1" className={css.Chart} style={{height: `${200 + byModulesChart.length * 33}px`}}>
                        <PieChart
                            data={byModulesChart}
                            filled={false}
                            hasChartMargin={false}
                            textTitle="Total questions"
                            textValue={totalQuestions.toString()}
                            legendAlign='right'
                            legendVerticalAlign='middle'
                            chartPosition='left'
                        />
                    </Row>
                </Col>
            </Col>
        </Col>
    )

});

export {QuestionnaireSide};
