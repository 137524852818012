import React, {FC} from "react";
import {iconsTypes} from "lavaa";
import {HealthBox, HealthBoxIconColor} from "../../Cards/HealthBoxes/HealthBox.com";
import {useDemo} from "../../../../../Hooks/useDemo";

interface IProps {
    onCardClick?: (cardName: string) => void;
}

// Stress Card
const StressCard: FC<IProps> = React.memo((props) => {

    const { onCardClick } = props;
    const {activeClient} = useDemo();

    const title = "Stress level";

    const personalRecords = activeClient?.client?.PersonalRecords ? activeClient?.client?.PersonalRecords[0] : null;

    const level: any = personalRecords?.StressLevel ?? null;
    let color:HealthBoxIconColor = "default";

    let value = '-';
    switch (level) {
        case 0:
            value = 'Low';
            color = "success";
            break;
        case 1:
            value = 'Average';
            color = "info";
            break;
        case 2:
            value = 'High';
            color = "danger";
            break;
    }


    return (
        <HealthBox
            title={title}
            icon={iconsTypes.stress}
            value={value}
			iconColor={color}
            cardName="StressLevel"
            onCardClick={onCardClick}
        />
    )
});

export {StressCard};
