import React, {FC} from "react";
import {iconsTypes} from "lavaa";
import {HealthBox, HealthBoxIconColor} from "../../Cards/HealthBoxes/HealthBox.com";
import {useDemo} from "../../../../../Hooks/useDemo";

interface IProps {
    onCardClick?: (cardName: string) => void;
}

// Waist Card
const WaistCard: FC<IProps> = React.memo((props) => {

    const { onCardClick } = props;
    const {activeClient} = useDemo();

    const title = "Waist";

    const personalRecords = activeClient?.client?.PersonalRecords ? activeClient?.client?.PersonalRecords[0] : null;
    const value: any = personalRecords?.WaistCircumference ?? "–";
    const gender = activeClient?.client?.Gender;

    let color:HealthBoxIconColor = "default";
    if(gender == "F" && value > 80){
        color = "danger";
    }
    else if(gender == "M" && value > 94){
        color = "success";
    }
    else if(value != "–"){
        color = "success";
    }

    return (
        <HealthBox
            title={title}
            icon={iconsTypes.waist}
            value={value}
			iconColor={color}
            cardName="Waist"
            onCardClick={onCardClick}
        />
    )
});

export {WaistCard};
