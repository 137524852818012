import {Row, Col, iconsTypes, Autocomplete, AutocompleteItemType} from 'lavaa';
import React, {FC} from 'react';
import css from './MenuSearch.module.scss';
import {useNavigate} from "react-router-dom";
import {useDemo} from "../../Hooks/useDemo";
import { useAccount } from '../../Hooks/UseAccount';

interface IProps {
    closeHandler: () => void
}

// Menu Search Dropdown
const MenuSearchDropdown: FC<IProps> = (props) => {
    const { closeHandler } = props;

    const {activeFlowId, fetchDemoClients, clientsAutocomplete, clearAutocomplete} = useDemo();
    const { HMSProjectId } = useAccount();
    const navigate = useNavigate();
    const searchRef = React.useRef<string>("");

    const autocompleteList: AutocompleteItemType[] = clientsAutocomplete.map((item: any) => {
        return {
            id: item.id,
            value: item.name
        }
    });

    function searchHandler(search: any){
        searchRef.current = search;
        if(search.length > 0){
            fetchDemoClients(HMSProjectId, activeFlowId, search, true);
        }
    }

    function chooseHandler(item: AutocompleteItemType | null){
        if(item){
            navigate(`/patients/${item.id}`);
            clearAutocomplete();
        }
        else{
            if(searchRef.current.length > 0){
                navigate(`/search/${searchRef.current}`);
            }
        }
        closeHandler();
    }

    return (
        <>
            <div className={css.DropdownLayer} onClick={closeHandler}/>
            <Col className={css.Dropdown}>
                <Row alignitems='center' paddingLeft='1.25rem' paddingTop='0.75rem' paddingRight='1.25rem' paddingBottom='0.75rem'>
                    <Autocomplete
                        placeholder="Search by patient name"
                        onChange={searchHandler}
                        onSelect={chooseHandler}
                        icon={iconsTypes.search}
                        autocompleteList={autocompleteList}
                        type="topBar"
                        autofocus={true}
                    />
                </Row>
            </Col>
        </>
    );
};

export { MenuSearchDropdown };