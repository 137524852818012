import { createSlice } from '@reduxjs/toolkit';
import {IActiveClientType, IClientGuidelinesType, IClientType, IModuleType} from "./Demo.data";
import { IOutput } from "../../../Components/FlowOutput/FlowOutput.types";
import { formatDate } from "../../../Tools/date";

interface IDemoState {
  modules: IModuleType[],
  clients: IClientType[],
  clientsLoading: boolean,
  activeClient: IActiveClientType,
  activeClientGuidelines: IClientGuidelinesType[],
  activeFlowId: string,
  localFlowId: string,
  output: IOutput[],
  page: number,
  sorting: {
    module: string,
    asc: boolean
  },
  runState: {
    startTime: number,
    lastUpdate: number,
    isRunning: boolean,
  },
  lastMessage: {
    message: string,
    nodeId: string,
  },
  isSingleClientRun: boolean,
  clientsAutocompleteWait: number,
  clientsAutocomplete: IClientType[],
};

// Initial Active Client State
const initialActiveClient = {
  client: {},
  results: [],
  treatments: [],
  medications: [],
};

const initialState: IDemoState = {
  modules: [],
  clients: [],
  clientsLoading: false,
  activeClient: { ...initialActiveClient },
  activeClientGuidelines: [],
  activeFlowId: '',
  localFlowId: '',
  output: [],
  page: 0,
  sorting: {
    module: '',
    asc: false
  },
  runState: {
    startTime: 0,
    lastUpdate: 0,
    isRunning: false,
  },
  lastMessage: {
    message: '',
    nodeId: ''
  },
  isSingleClientRun: false,
  clientsAutocomplete: [],
  clientsAutocompleteWait: 0,
};

export const demoSlice = createSlice({
  name: 'Demo',
  initialState,
  reducers: {

    // Get Modules
    getDemoModulesAction: (state, action) => {
      console.log('Get Demo Modules', action.payload);
    },

    // Receive Modules
    receiveDemoModulesAction: (state, action) => {
      let modules = action.payload;
      modules = Array.from(new Set(modules.map((item: any) => item.ModuleUID))).map((uid: any) => modules.find((item: any) => item.ModuleUID === uid));
      console.log('Receive Demo Modules', action);
      state.modules = modules;
    },

    // Get Clients
    getDemoClientsAction: (state, action) => {
      if(state.clientsAutocompleteWait === 0) {
        state.clientsLoading = true;
      }
    },

    // Receive Clients
    receiveDemoClientsAction: (state, action) => {
      console.log('Receive Demo Clients', action);
      const clients = action.payload.data.Clients;

      if(state.clientsAutocompleteWait > 0){
        state.clientsAutocomplete = clients;
        state.clientsAutocompleteWait -= 1;
      }
      else{
        // Validate data
        if(Array.isArray(clients)){
          state.clients = clients;
        }

        state.clientsLoading = false;
      }
    },

    clearDemoClientsAction: (state) => {
        state.clients = [];
    },

    // Set Autocomplete Loading
    setAutocompleteAction: (state) => {
      state.clientsAutocompleteWait += 1;
    },

    // Clear Autocomplete search
    clearAutocompleteAction: (state) => {
      state.clientsAutocompleteWait = 0;
      state.clientsAutocomplete = [];
    },

    // Get Client
    getDemoClientAction: (state, action) => state,

    // Receive Client
    receiveDemoClientAction: (state, action) => {
      console.log('Receive Demo Client', action.payload.data)
      state.activeClient = action.payload.data;
    },

    // Reset Active Client
    resetActiveClientAction: (state) => {
      state.activeClient = { ...initialActiveClient };
    },

    // Get Client Guidelines
    getDemoClientGuidelinesAction: (state, action) => state,

    // Receive Client Guidelines
    receiveDemoClientGuidelinesAction: (state, action) => {
      console.log('Receive Demo Client Guidelines', action.payload.data);
      state.activeClientGuidelines = action.payload.data;
    },

    // Change Client Guideline Status
    changeClientGuidelineStatusAction: (state, action) => state,

    // Receive Change Client Guideline Status
    receiveChangeClientGuidelineStatusAction: (state, action) => {
      state.activeClient = action.payload.data;
    },

    // Change Guideline Status
    changeGuidelineStatusAction: (state, action) => state,

    // Receive Change Guideline Status
    receiveChangeGuidelineStatusAction: (state, action) => {
      state.activeClientGuidelines = action.payload.data;
    },

    // Run Clients
    runDemoClientsAction: (state, action) => {
      const time = performance.now();
      state.runState = {
        startTime: time,
        lastUpdate: time,
        isRunning: true
      }
      state.output = [];
      state.lastMessage = {
        message: '',
        nodeId: ''
      }
      state.isSingleClientRun = false;
    },

    // Run Client
    runDemoClientAction: (state, action) => {
      const time = performance.now();
      state.runState = {
        startTime: time,
        lastUpdate: time,
        isRunning: true
      }
      state.output = [];
      state.lastMessage = {
        message: '',
        nodeId: ''
      }
      state.isSingleClientRun = true;
    },

    // Receive Run Demo
    receiveRunDemoAction: (state, action) => {
      console.log('Receive Run Demo', action);
    },

    // Update FlowId
    updateFlowIdAction: (state, action) => {
      state.activeFlowId = action.payload;
      state.page = 0;
    },

    // Update Local FlowId
    updateLocalFlowIdAction: (state, action) => {
      state.localFlowId = action.payload;
    },

    // Clear Demo Client
    clearClientAction: (state, action) => {
      state.activeClient = {
        client: {},
        results: [],
        treatments: [],
        medications: [],
      };
      state.activeClientGuidelines = [];
    },

    // Add Data Output
    addDataOutputAction: (state, action) => {
      const color = action.payload[1] || 'default';
      const output: IOutput = {
        message: action.payload[0],
        date: formatDate(new Date()),
        color: color,
        link: action.payload[2]
      }
      state.output.push(output);
    },

    // Next Page
    nextPageAction: (state) => {
      state.page++;
      return state;
    },

    // Prev Page
    prevPageAction: (state) => {
      state.page--;

      if (state.page < 0) {
        state.page = 0;
      }
      
    },

    setPageAction: (state, action) => {
      if(state.clientsLoading) return;
      state.page = action.payload;
    },
    
    // Update Run State
    updateRunStatusAction: (state, action) => {
      state.runState.lastUpdate = performance.now();
    },

    // Update Run State
    stopRunAction: (state, action) => {
      const time = performance.now();
      const deltaTime = (time - state.runState.startTime) / 1000;
      const message = `Execution time ${deltaTime.toFixed(2)} seconds`;

      const output: IOutput = {
        message: message,
        date: formatDate(new Date()),
        color: 'default'
      }

      state.runState.isRunning = false;
      state.output.push(output);
    },

    // Add Last Message Info
    addLastMessageInfoAction: (state, action) => {
      state.lastMessage = {
        message: action.payload[0],
        nodeId: action.payload[1]
      };
    },

    // Set Sorting 
    setSortingAction: (state, action) => {
      const { payload } = action;
      state.sorting = {...payload};
    },

    // Set Sorting
    resetDemoAction: () => {
      return initialState;
    },
  }
});

export const {
  getDemoModulesAction,
  receiveDemoModulesAction,
  getDemoClientsAction,
  receiveDemoClientsAction,
  clearDemoClientsAction,
  setAutocompleteAction,
  clearAutocompleteAction,
  getDemoClientAction,
  receiveDemoClientAction,
  resetActiveClientAction,
  getDemoClientGuidelinesAction,
  receiveDemoClientGuidelinesAction,
  changeClientGuidelineStatusAction,
  receiveChangeClientGuidelineStatusAction,
  changeGuidelineStatusAction,
  receiveChangeGuidelineStatusAction,
  runDemoClientsAction,
  runDemoClientAction,
  updateFlowIdAction,
  updateLocalFlowIdAction,
  clearClientAction,
  addDataOutputAction,
  nextPageAction,
  prevPageAction,
  setPageAction,
  updateRunStatusAction,
  stopRunAction,
  addLastMessageInfoAction,
  setSortingAction,
  resetDemoAction
} = demoSlice.actions;
export default demoSlice.reducer;