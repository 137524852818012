import React, {FC} from "react";
import css from "../style.module.scss";
import {Col, Row, Text, iconsTypes, Button} from "lavaa";
import {QuestionnaireConfirmFields} from "../../../Questionnaire/confirm";

interface IProps {
    inputs: {[k: string]: any},
    onReturn: () => void
    onConfirm: () => void,
    loading: boolean
}

// Questionnaire Submit Page
const QuestionnaireSubmitPage: FC<IProps> = React.memo((props) => {

    const { onReturn, inputs, onConfirm, loading } = props;

    const questionsCount = Object.values(inputs).length;

    function confirmHandler(){
        if(loading) return;
        onConfirm();
    }

    return (
        <Col className={css.Wrapper}>
            <Col>
                <Col>
                    <Row alignitems="center" className={css.Container} grow="1" marginBottom="1rem">
                        <Col justifycontent="center">
                            <Text size='x3' bold={true}>Review Answers</Text>
                        </Col>
                        <Col marginLeft="1.75rem">
                            <Text size='x2'>{questionsCount} Question{questionsCount != 1 ? 's' : ''}</Text>
                        </Col>
                    </Row>
                    <Row>
                        <Text size='x2'>Kindly review your responses before proceeding and CONFIRM your answers</Text>
                    </Row>
                </Col>
            </Col>
            <Col className={css.FieldsWrapper} marginTop="2rem">
                <QuestionnaireConfirmFields inputs={inputs}/>
            </Col>
            <Row justifycontent="end" paddingBottom="1rem" marginTop="1rem">
                <Col marginRight="1.75rem">
                    <Button
                        icon={iconsTypes.arrowLeft}
                        text="Back to form"
                        type={"secondary"}
                        onClick={onReturn}
                    />
                </Col>
                <Col>
                    <Button
                        text="Confirm"
                        type={"primary"}
                        onClick={confirmHandler}
                        isLoading={loading}
                    />
                </Col>
            </Row>
        </Col>
    )
});

export {QuestionnaireSubmitPage};
