import React, {FC} from "react";
import {CardItem} from "../Card/Card";
import {MedicalHistory} from "../Cards/MedicalHistory/MedicalHistory.component";
import {LabResults} from "../Cards/LabResults/LabResults.component";
import {Medications} from "../Cards/Medications/Medications.component";
import {DiagnosisCode} from "../Cards/DiagnosisCode/DiagnosisCode.component";
import css from "../Card/Card.module.scss";
import {HealthBoxes} from "../Cards/HealthBoxes/HealthBoxes.com";
import {iconsTypes, ScrollContainer} from "lavaa";
import {usePatientPageDeviceType} from "../../../../Hooks/UsePatientPageDeviceType";

interface IProps {
    onCardClick?: (cardName: string) => void;
}

// Health Card
const HealthCard: FC<IProps> = React.memo((props) => {

    const { onCardClick } = props;
    const viewType = usePatientPageDeviceType();
    const isTablet = viewType === 'tablet';

    let medicationSize: 'default' | 'doubled' = 'doubled';
    let diagnosisSize: 'default' | 'doubled' = 'doubled';

    if(isTablet){
        medicationSize = 'default';
        diagnosisSize = 'default';
    }

    return (
        <ScrollContainer>
            <div className={css.Grid}>
                <CardItem title="Medical History" icon={iconsTypes.medicalHistory} hasBorder={true}>
                    <MedicalHistory/>
                </CardItem>
                <HealthBoxes size="doubled" onCardClick={onCardClick} />
                <CardItem title="Lab Results" icon={iconsTypes.labResults} hasSearch={true} hasBorder={true} searchPlaceholder="Search by results" size="doubled">
                    <LabResults/>
                </CardItem>
                <CardItem title="Medication" size={medicationSize} icon={iconsTypes.medicalHistory} hasBorder={true}>
                    <Medications/>
                </CardItem>
                <CardItem title="Diagnoses Code" size={diagnosisSize} hasSearch={true} searchPlaceholder="Search By Code" hasBorder={true}>
                    <DiagnosisCode/>
                </CardItem>
            </div>
        </ScrollContainer>
    )
});

export {HealthCard};
