import React, {FC} from "react";
import {iconsTypes} from "lavaa";
import {HealthBox, HealthBoxIconColor} from "../../Cards/HealthBoxes/HealthBox.com";
import {useDemo} from "../../../../../Hooks/useDemo";
import { IResultType } from "../../../../../Redux/Slices/Demo/Demo.data";

interface IProps {
    onCardClick?: (cardName: string) => void;
}

// High Risk Card
const HighRiskCard: FC<IProps> = React.memo((props) => {
    
    const { onCardClick } = props;
    const {activeClient} = useDemo();

    const title = "Major Red Flags";
    const icon = iconsTypes.warningRounded;

    const riskLabels = ['high risk', 'extreme risk'];
    const results = activeClient?.results.filter((result: IResultType) => result.moduleType !== '6');
    
    const highRisk = results?.filter((item: any) =>
        item.resultPercentage >= 100 || (item.resultGroupName && riskLabels.includes(item.resultGroupName.toLowerCase()))
    );

    const count = highRisk?.length ?? 0;
    const iconColor: HealthBoxIconColor = count > 0 ? "danger" : "default";

    return (
        <HealthBox
            title={title}
            icon={icon}
            value={count}
			iconColor={iconColor}
            cardName="HighRiskDiagnosis"
            onCardClick={onCardClick}
        />
    )
});

export {HighRiskCard};
