import { useAccount } from '../../Hooks/UseAccount';
import css from './Logo.module.scss';
import {NavLink} from "react-router-dom";
import {useTheme} from "../../Hooks/UseTheme";
import {LogoDark} from "./Logo.dark.com";
import {LogoLight} from "./Logo.light.com";

// Logo
const Logo = () => {

    // Hooks
    const { accountLogo } = useAccount();
    const { theme } = useTheme();

    return (
        <NavLink to="/" className={ css.Logo }>
            {
                accountLogo ? <div dangerouslySetInnerHTML={{__html: accountLogo}}/>
                : theme === 'dark' ? <LogoDark/> : <LogoLight/>
            }
        </NavLink>
    );
};

export {Logo};