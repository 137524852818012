import { IOutputType } from "../../../Components/FlowOutput/FlowOutput.types";
import {
  addDataOutputAction,
  addLastMessageInfoAction,
  stopRunAction,
  updateRunStatusAction,
  updateFlowIdAction,
  updateLocalFlowIdAction,
  getDemoClientAction, getDemoClientGuidelinesAction
} from "./Demo.slice";
import { history } from "../../../Constants/History";
import {addSnackbar} from "lavaa";

export const receiveDemoRunCallback = ( store: any, payload: any) => {
  const isSingleClientRun = store.getState().demo.isSingleClientRun;
  const data = payload.data;
  const message = data.message, step = data.flowStep, nodeInProgress = data.nodeInProgressUID ?? '';

  let type: IOutputType = 'default';
  store.dispatch(updateRunStatusAction([]));

  let link = null
  if(step === 3) {
    link = data.reportUrl;
    type = 'success';

    if(isSingleClientRun){
      store.dispatch(updateLocalFlowIdAction(data.flowUID));
    }
    else{
      store.dispatch(updateFlowIdAction(data.flowUID));
      setTimeout(() => { history.push("/patients"); }, 2000);
    }
  }

  if(step === 4){ // Fault
    type = 'error';
    store.dispatch(addDataOutputAction([data.errorMessage, type]));
  }

  // Remove repeat messages
  const lastMessage = store.getState().demo.lastMessage;
  if(lastMessage.message !== message || lastMessage.nodeId !== nodeInProgress) {
    store.dispatch(addDataOutputAction([message, type]));
  }
  store.dispatch(addLastMessageInfoAction([message, nodeInProgress]));

  if(link){
    // TODO
    const mess = 'Download project report';
    store.dispatch(addDataOutputAction([mess, 'default', link]));
  }

  if(step === 3 || step === 4){
    store.dispatch(stopRunAction([]));
  }
}

// Trigger when "by Diagnoses" updated
export const receiveChangeClientGuidelineStatusCallback = (store: any, payload: any) => {
  
  // Look for client ID
  const clientId = payload.data.client?.SourceClientID ?? "";
  if(!clientId) return;
  // console.log('store.getState()', store.getState());
  
  // const localFlowId = store.getState().demo.localFlowId ?? "";
  const hmsProjectId = store.getState()?.account?.HMSProjectId ?? "";
  addSnackbar('success', 'Great!', 'The changes was saved successfully');

  store.dispatch(getDemoClientGuidelinesAction([clientId.toString(), hmsProjectId]));
}

// Trigger when "by Priority" updated
export const receiveChangeGuidelineStatusCallback = ( store: any, payload: any) => {

  // Look for client ID
  const clientId = payload.data[0]?.clientUID ?? "";
  if(!clientId) return;

  // const localFlowId = store.getState().demo.localFlowId ?? "";
  const hmsProjectId = store.getState()?.account?.HMSProjectId ?? "";
  addSnackbar('success', 'Great!', 'The changes was saved successfully');

  store.dispatch(getDemoClientAction([clientId.toString(), '', hmsProjectId]));
}