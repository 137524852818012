import React, {FC, useState} from "react";
import {GuidelinesByDiagnoses} from "./byDiagnoses";
import {TreatmentByPriority} from "./byPriority";
import css from "../Card/Card.module.scss";
import {HealthBoxes} from "../Cards/HealthBoxes/HealthBoxes.com";
import {Col, ScrollContainer} from "lavaa";
import {GuidelineParams} from "../GuidelineParams/GuidelineParams";

interface IProps {
    onCardClick?: (cardName: string) => void;
}

// Treatment Page
const TreatmentPage: FC<IProps> = React.memo((props) => {

    const { onCardClick } = props;

    // States
    const [guidelinesByPriority, setGuidelinesByPriority] = useState(false);
    const [guidelinesFilter, setGuidelinesFilter] = useState('all');

    return (
        <ScrollContainer>
            <div className={css.Grid} data-type="double">
                <HealthBoxes size="full-width" onCardClick={onCardClick} />
                <Col className={css.FullWidthBlock}>
                    <GuidelineParams
                        active={guidelinesByPriority}
                        onChangeToggle={setGuidelinesByPriority}
                        guidelinesFilter={guidelinesFilter}
                        onChangeFilter={setGuidelinesFilter}
                    />
                    {
                        guidelinesByPriority
                            ? <TreatmentByPriority filter={guidelinesFilter}/>
                            : <GuidelinesByDiagnoses filter={guidelinesFilter}/>
                    }
                </Col>
            </div>
        </ScrollContainer>
    )
});

export {TreatmentPage};
