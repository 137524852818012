import React, {FC} from "react";
import css from "../Card/Card.module.scss";
import {HealthBoxes} from "../Cards/HealthBoxes/HealthBoxes.com";
import {QuestionnaireForm} from "./QuestionnaireForm.com";
import {classNames} from "../../../../Tools";
import {iconsTypes, ScrollContainer} from "lavaa";
import {CardItem} from "../Card/Card";
import {QuestionnaireSide} from "./side/QuestionnaireSide.com";
import {usePatientPageDeviceType} from "../../../../Hooks/UsePatientPageDeviceType";

interface IProps {
    onCardClick?: (cardName: string) => void;
}

// Questionnaire Page 
const QuestionnairePage: FC<IProps> = React.memo((props) => {

    const { onCardClick } = props;

    const viewType = usePatientPageDeviceType();
    const isTablet = viewType === 'tablet';

    const questionnaireSize: 'default' | 'doubled' = isTablet ? 'default' : 'doubled';

    return (
        <ScrollContainer>
            <div className={css.Grid} data-type="double">
                <HealthBoxes size="full-width" onCardClick={onCardClick} />
                <div className={classNames(css.Grid, css.FullWidthBlock)}>
                    <CardItem hSize={questionnaireSize} size="doubled">
                        <QuestionnaireForm/>
                    </CardItem>
                    <CardItem title="Questionnaire Statistics" icon={iconsTypes.cmsHcc} hasBorder={true} size="doubled">
                        <QuestionnaireSide/>
                    </CardItem>
                </div>
            </div>
        </ScrollContainer>
    )

});

export {QuestionnairePage};
