import React, {FC} from "react";
import {iconsTypes} from "lavaa";
import {HealthBox, HealthBoxIconColor} from "../../Cards/HealthBoxes/HealthBox.com";
import {useDemo} from "../../../../../Hooks/useDemo";

interface IProps {
    onCardClick?: (cardName: string) => void;
}

// Pain Level Card
const PainCard: FC<IProps> = React.memo((props) => {

    const { onCardClick } = props;
    const {activeClient} = useDemo();

    const title = "Pain level";

    const personalRecords = activeClient?.client?.PersonalRecords ? activeClient?.client?.PersonalRecords[0] : null;

    const level = personalRecords?.PainLevel ?? "-";

    let value = '-';
    let color:HealthBoxIconColor = "default";

    if(level <= 3){
        value = 'Low';
        color = "success";
    }
    else if(level <= 6){
        value = 'Average';
        color = "info";
    }
    else if(level <= 10){
        value = 'High';
        color = "danger";
    }


    return (
        <HealthBox
            title={title}
            icon={iconsTypes.pain}
            value={value}
            iconColor={color}
            cardName="PainLevel"
            onCardClick={onCardClick}
        />
    )
});

export {PainCard};
