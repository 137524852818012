import React, {FC} from "react";
import {iconsTypes} from "lavaa";
import {HealthBox, HealthBoxIconColor} from "../../Cards/HealthBoxes/HealthBox.com";
import {useDemo} from "../../../../../Hooks/useDemo";

interface IProps {
    onCardClick?: (cardName: string) => void;
}

// BMI Card
const BmiCard: FC<IProps> = React.memo((props) => {
    
    const { onCardClick } = props;
    const {activeClient} = useDemo();

    const title = "BMI";
    console.log('activeClient', activeClient);
    

    const personalRecords = activeClient?.client?.PersonalRecords ? activeClient?.client?.PersonalRecords[0] : null;
    const bmi = personalRecords?.BMI?.toFixed(1) ?? "–";
    
    let color:HealthBoxIconColor = "default";
    if(bmi < 18.5){
        color = "danger";
    }
    else if(bmi >= 18.5 && bmi <= 24.9){
        color = "success";
    }
    else if(bmi > 24.9){
        color = "danger";
    }

    return (
        <HealthBox
            title={title}
            icon={iconsTypes.bmi}
            value={bmi}
			iconColor={color}
            cardName="BMI"
            onCardClick={onCardClick}
        />
    )
});

export {BmiCard};
