
export enum MenuHealthBoxes {
    WorryScore = 'Lavaa Worry Score',
    // Attribution = 'Attribution',
    // Hospitalization = 'Hospitalization',
    HighRisk = 'High Risk Diagnoses',
    ClinicalResearch = 'Clinical Trial',
    RafScore = 'Raf Score',
    BMI = 'BMI',
    BloodPressure = 'Blood Pressure',
    Pulse = 'Pulse',
    Height = 'Height',
    Weight = 'Weight',
    Waist = 'Waist',
    Race = 'Race',
    Activity = 'Activity level',
    Stress = 'Stress level',
    Temperature = 'Body temperature',
    CO2 = 'CO2 Saturation',
    Pain = 'Pain Level',
    Respiration = 'Respiration',
    A1C = 'Last A1C',
    GFR = 'Last GFR',
    LDL = 'Last LDL',
}

export const defaultHealthBoxes = [
    MenuHealthBoxes.WorryScore,
    MenuHealthBoxes.HighRisk,
    MenuHealthBoxes.ClinicalResearch,
    MenuHealthBoxes.RafScore,
    MenuHealthBoxes.BMI,
    MenuHealthBoxes.BloodPressure,
    MenuHealthBoxes.Activity,
];