import React, {FC} from "react";
import {iconsTypes} from "lavaa";
import {HealthBox, HealthBoxIconColor} from "../../Cards/HealthBoxes/HealthBox.com";
import {useDemo} from "../../../../../Hooks/useDemo";

interface IProps {
    onCardClick?: (cardName: string) => void;
}

// CO2 Card
const CO2Card: FC<IProps> = React.memo((props) => {

    const { onCardClick } = props;
    const {activeClient} = useDemo();

    const title = "CO2 Saturation";

    const medicalHistories = activeClient?.client?.MedicalHistories ? activeClient?.client?.MedicalHistories[0] : null;

    // TODO: Check if this is the correct value
    const value = medicalHistories?.O2SaturationLevel ?? "-";

    let color:HealthBoxIconColor = "default";
    if(value < 35){
        color = "danger";
    }
    else if(value >= 35 && value <= 45){
        color = "success";
    }
    else if(value > 45){
        color = "danger";
    }

    return (
        <HealthBox
            title={title}
            icon={iconsTypes.co2}
            value={value}
			iconColor={color}
            cardName="CO2Saturation"
            onCardClick={onCardClick}
        />
    )
});

export {CO2Card};
