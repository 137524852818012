import React, {FC} from "react";
import {Col, Row, Text} from "lavaa";
import {useDemo} from "../../../../../Hooks/useDemo";
import {useClients} from "../../../../../Hooks/UseClients";
import {convertValueWithScheme} from "../../../../../Tools/schemeData";
import css from "./Vitals.module.scss";
import {classNames} from "../../../../../Tools";
import {VitalsEmpty} from "./VitalsEmpty.com";

interface IData {
    label: string,
    value: string,
}

const Vitals: FC = React.memo(() => {

    const {activeClient} = useDemo();
    const {clientScheme} = useClients();

    if (
        !activeClient?.client
        || !activeClient?.client?.Vitals || !activeClient?.client?.Vitals.length
        || !clientScheme?.Vitals
    ) return <VitalsEmpty/>;

    const personalRecords = activeClient?.client?.Vitals[0];

    const data: IData[] = [];
    Object.entries(personalRecords).forEach(([key, value]) => {
        const scheme = clientScheme.Vitals.subfields[key];
        if (!scheme) return;

        data.push(
            {
                label: scheme.label,
                value: convertValueWithScheme(scheme, value),
            }
        )
    });

    if(data.length === 0) return <VitalsEmpty/>;

    return (
        <Col className={css.List} marginTop="1rem">
            {
                data.map((item: any, index: number) => (
                    <Row key={index} className={classNames(css.Item, index >= data.length - 2 ? css.Last : "")}>
                        <Text text={item.label} size="x2" ellipsis={true} className={css.ItemLabel}/>
                        <Text text={item.value} size="x2" className={css.ItemValue} bold={true}/>
                    </Row>
                ))
            }
        </Col>
    )
});

export {Vitals};
