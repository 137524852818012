import React, {FC} from "react";
import {useDemo} from "../../../../../Hooks/useDemo";
import {HealthBoxIndicator, HealthBoxIndicatorColor} from "../../Cards/HealthBoxes/HealthBoxIndicator.com";
import { WorryScoreInfo } from "../../../../../Tools/WorryScoreInfo";

interface IProps {
    onCardClick?: (cardName: string) => void;
}

// Worry Score Card
const WorryScoreCard: FC<IProps> = React.memo((props) => {
    
    const { onCardClick } = props;
    const {activeClient} = useDemo();

    const title = "Operational Worry Score";

    const ws:any = activeClient?.worryScore ?? undefined;
    const value = ws ? ws : "";

    const wsInfo = ws ? WorryScoreInfo[ws] : '';

    let color: HealthBoxIndicatorColor = "default-risk";
    let label: any = '-'; //<>No<br/>data</>;

    if(ws !== undefined && ws > 0) {
        if(ws <= 3) {
            color = 'no-risk';
            label = <>No<br/>risk</>;
        }
        else if(ws <= 6) {
            color = 'medium-high-risk';
            label = <>Medium<br/>risk</>;
        }
        else {
            color = 'high-risk';
            label = <>High<br/>risk</>;
        }
    }

    return (
        <HealthBoxIndicator
            title={title}
            value={value}
            wsInfo={wsInfo}
            label={label}
			color={color}
            cardName="OperationalWorryScore"
            onCardClick={onCardClick}
        />
    )
});

export {WorryScoreCard};
