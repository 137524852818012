import React, {FC} from "react";
import {Col, iconsTypes, Row, Text, Dropdown} from "lavaa";
import css from "./GuidelineParams.module.scss";

const filterByDiagnosesData: any = [
    { id: "with-g", value: "Modules with insights" },
    { id: "without-g", value: "Modules without insights" },
    { id: "checked", value: "Completed insights" },
    { id: "unchecked", value: "Uncompleted insights" },
    { id: "all", value: "All" },
];
const filterByPriorityData: any = [
    { id: "checked", value: "Completed guidelines" },
    { id: "unchecked", value: "Uncompleted guidelines" },
    { id: "all", value: "All" },
];

interface IProps {
    active: boolean,
    onChangeToggle: (arg0: boolean) => void,
    guidelinesFilter: string,
    onChangeFilter: (arg0: string) => void
}

// Guideline Params
const GuidelineParams: FC<IProps> = React.memo((props) => {
    let { active, onChangeToggle, guidelinesFilter, onChangeFilter } = props;

    function selectHandler(data: any){
        onChangeFilter(data.id);
    }

    const filterData = active ? filterByPriorityData : filterByDiagnosesData;

    const selected = filterData.find((item: any) => item.id === guidelinesFilter);

    return (
        <Col paddingTop="1rem" marginBottom="1rem" className={css.Block}>
            <Row alignitems="center" grow="1" justifycontent="space-between">
                <Col>
                    <Dropdown icon={iconsTypes.filter} type="button-simplified" placeholder="Filter" data={ filterData } selected={selected} onSelect={selectHandler}></Dropdown>
                </Col>
                <Row alignitems="center" justifycontent="end">
                    <Row onClick={() => {onChangeToggle(false)}} className={css.Button} data-active={!active} marginRight="1.75rem">
                        <Text text="Insights by Diagnoses" size="x3" bold={true} className={css.Text}/>
                    </Row>
                    <Row onClick={() => {onChangeToggle(true)}} className={css.Button} data-active={active}>
                        <Text text="Insights by Priority" size="x3" bold={true} className={css.Text}/>
                    </Row>
                </Row>
            </Row>
        </Col>

    )
});

export {GuidelineParams};