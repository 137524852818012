import {iconsTypes} from "lavaa";

export type TabType = {
    title: string;
    icon: number;
}

export const patientPageTabs: TabType[] = [
    {
        title: 'Personal Info',
        icon: iconsTypes.personOutline
    },
    {
        title: 'Health Card',
        icon: iconsTypes.healthCard
    },
    {
        title: 'Insights',
        icon: iconsTypes.insight
    },
    {
        title: 'Basic Assessment',
        icon: iconsTypes.basicAssessment
    },
    {
        title: 'Surveys',
        icon: iconsTypes.queryStats
    },
    {
        title: 'CMS-HCC Opportunities',
        icon: iconsTypes.cmsHcc
    },
];