import React, {FC} from "react";
import {iconsTypes} from "lavaa";
import {HealthBox} from "../../Cards/HealthBoxes/HealthBox.com";
import {useDemo} from "../../../../../Hooks/useDemo";

interface IProps {
    onCardClick?: (cardName: string) => void;
}

// Race Card
const RaceCard: FC<IProps> = React.memo((props) => {

    const { onCardClick } = props;
    const {activeClient} = useDemo();

    const title = "Race";

    const personalRecords = activeClient?.client?.PersonalRecords ? activeClient?.client?.PersonalRecords[0] : null;
    const value = personalRecords?.Race ?? "–";

    return (
        <HealthBox
            title={title}
            icon={iconsTypes.race}
            value={value}
			iconColor={"default"}
            cardName="Race"
            onCardClick={onCardClick}
        />
    )
});

export {RaceCard};
