import React, {FC} from "react";
import css from "../Card/Card.module.scss";
import {CardItem} from "../Card/Card";
import {RafScoreStat} from "./Stat/RafScoreStat.com";
import {RafScoreMissing} from "./Compare/RafScoreMissing.com";
import {HealthBoxes} from "../Cards/HealthBoxes/HealthBoxes.com";
import {classNames} from "../../../../Tools";
import {ScrollContainer} from "lavaa";
import {usePatientPageDeviceType} from "../../../../Hooks/UsePatientPageDeviceType";

interface IProps {
    onCardClick?: (cardName: string) => void;
}

// Raf Score Page
const RafScorePage: FC<IProps> = React.memo((props) => {

    const { onCardClick } = props;

    const viewType = usePatientPageDeviceType();
    const isTablet = viewType === 'tablet';

    const size: 'default' | 'doubled' = isTablet ? 'default' : 'doubled';

    return (
        <ScrollContainer>
            <div className={css.Grid} data-type="double">
                <HealthBoxes size="full-width" onCardClick={onCardClick} />
                <div className={classNames(css.Grid, css.FullWidthBlock)}>
                    <CardItem hSize={size}>
                        <RafScoreMissing
                            title="Suggested Codes"
                            sourceDataKey="lavaaHCC"
                            comparisonDataKeys={['emrhcc', 'billingHCC']}
                            emptyText="No Suggested Codes"
                        />
                    </CardItem>
                    <CardItem size="doubled">
                        <RafScoreStat/>
                    </CardItem>
                    <CardItem hSize={size}>
                        <RafScoreMissing
                            title="Missed Codes"
                            sourceDataKey="emrhcc"
                            comparisonDataKeys={['billingHCC']}
                            emptyText="No Missed Codes"
                        />
                    </CardItem>
                </div>
            </div>
        </ScrollContainer>

    )
});

export {RafScorePage};
