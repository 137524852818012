import React, {FC, useContext, useEffect, useState} from "react";
import { ModalsCtx } from "../../../../Context/Modals.context";
import { Modal} from 'lavaa';
import {MenuHealthBoxesContent} from "./MenuHealthBoxesContent.com";
import {MenuHealthBoxes} from "./MenuHealthBoxes.type";

// Menu Health Boxes Modal
const MenuHealthBoxesModal: FC<any> = () => {

    const { isMenuHealthBoxesModalActive, setMenuHealthBoxesModalActive, menuHealthBoxes, setMenuHealthBoxes } = useContext(ModalsCtx);

    const [menuWidgetsLocal, setMenuWidgetsLocal] = useState<Array<MenuHealthBoxes>>([]);

    useEffect(() => {
        if(isMenuHealthBoxesModalActive) initMenuWidgets();
    }, [isMenuHealthBoxesModalActive]);

    function initMenuWidgets(){
        const local: Array<MenuHealthBoxes> = [];
        const values = Object.values(MenuHealthBoxes);
        menuHealthBoxes.map((value: MenuHealthBoxes) => {
            if (values.includes(value)) {
                local.push(value);
            }
        });

        setMenuWidgetsLocal(local);
    }

    function saveChanges(){
        setMenuHealthBoxes(menuWidgetsLocal);
    }

    // Handle Close
    const handleClose = () => {
        setMenuHealthBoxesModalActive(false);
    };

    // Handle Cancel
    const handleCancel = () => {
        setMenuHealthBoxesModalActive(false);
    };

    // Handle Ok
    const handleOk = () => {
        saveChanges();
        setMenuHealthBoxesModalActive(false);
    };


    return <Modal 
        active={ isMenuHealthBoxesModalActive }
        onClose={ handleClose } 
        onCancel={ handleCancel }
        onOk={ handleOk }
        title="Settings"
        okText="Save"
        cancelText="Cancel"
        type="small"
    >
        <MenuHealthBoxesContent menuWidgetsLocal={menuWidgetsLocal} setMenuWidgetsLocal={setMenuWidgetsLocal}/>
    </Modal>
};

export { MenuHealthBoxesModal };