import React, {FC} from "react";
import {iconsTypes} from "lavaa";
import {HealthBox, HealthBoxIconColor} from "../../Cards/HealthBoxes/HealthBox.com";
import {useDemo} from "../../../../../Hooks/useDemo";

interface IProps {
    onCardClick?: (cardName: string) => void;
}

// Respiration Card
const RespirationCard: FC<IProps> = React.memo((props) => {

    const { onCardClick } = props;
    const {activeClient} = useDemo();
    const title = "Respiration";

    const personalRecords = activeClient?.client?.PersonalRecords ? activeClient?.client?.PersonalRecords[0] : null;
    const level = personalRecords?.PainLevel ?? "-";
    const age = activeClient?.client?.FullYears ?? null;

    const value = level ? level : "-"
    let color:HealthBoxIconColor = "default";
    if(age >= 13 && value < 12){
        color = "danger";
    }
    else if(age >= 13 && value > 20){
        color = "danger";
    }
    else if(age < 13 && value < 20){
        color = "danger";
    }
    else if(age < 13 && value > 40){
        color = "danger";
    }
    else if(value != '-'){
        color = "success";
    }

    return (
        <HealthBox
            title={title}
            icon={iconsTypes.respiration}
            value={value}
			iconColor={color}
            cardName="Respiration"
            onCardClick={onCardClick}
        />
    )
});

export {RespirationCard};
