export enum DashboardReportNames {
    DischargeLast48H = 'ReportDailyDischargeLast48H',
    HighWorryScore = 'ReportDailyHighWorryScore',
    LongOverdue = 'ReportDailyLongOverdue',
    NoAwvThisYear = 'ReportDailyNoAwvThisYear',
    NoAwvThisYearLastYear = 'ReportDailyNoAwvThisYearLastYear',
    General = 'ReportGeneralReport',
    DischargeTCM = 'ReportDischargeTCM',
    AdmissionTCM = 'ReportAdmissionTCM',
}

// Report Titles
export const reportTitles: any = {
    [DashboardReportNames.DischargeLast48H]: 'Discharge last 48h - No Near Office Visit',
    [DashboardReportNames.HighWorryScore]: 'High Worry Score - No Near Office Visit',
    [DashboardReportNames.LongOverdue]: 'Long Overdue - Medium Worry Score',
    [DashboardReportNames.NoAwvThisYear]: 'No AWV this year - High Worry Score',
    [DashboardReportNames.NoAwvThisYearLastYear]: 'No AWV 2024 and 2025',
    [DashboardReportNames.General]: 'Patient Care Prioritization Overview',
    [DashboardReportNames.DischargeTCM]: 'Transitional Care Management',
    [DashboardReportNames.AdmissionTCM]: 'Current Admissions',
}

export const reportHeaders: any = {
    [DashboardReportNames.DischargeLast48H]: [
        'Patient', 'DischargeTime', 'CareNavigator', 'Telehealth', 'NextScheduledOfficeVisit', 'Call'
    ],
    [DashboardReportNames.HighWorryScore]: [
        'Patient', 'CareNavigator', 'WorryScore', 'Overdue', 'Call'
    ],
    [DashboardReportNames.LongOverdue]: [
        'Patient', 'CareNavigator', 'WorryScore', 'Overdue', 'Call'
    ],
    [DashboardReportNames.NoAwvThisYear]: [
        'Patient', 'CareNavigator', 'WorryScore', 'Call'
    ],
    [DashboardReportNames.NoAwvThisYearLastYear]: [
        'Patient', 'CareNavigator', 'WorryScore', 'Call'
    ],
    [DashboardReportNames.General]: [
        'Patient', 'CareNavigator', 'WorryScore', 'NextRequiredAppointment', 'Overdue', 'Call'
    ],
    [DashboardReportNames.DischargeTCM]: [
        'Patient', 'Pcp', 'PatientStatus', 'DischargeDate', 'PhoneCallDate', 'InPersonVisitDate', 'Call',
    ],
    [DashboardReportNames.AdmissionTCM]: [
        'Patient', 'Pcp', 'AdmissionDate', 'Call',
    ]
}