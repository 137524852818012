import React, {FC, useContext, useEffect} from "react";
import {AppCtx} from "../../Context/App.context";
import {PatientPageDesktop} from "./Desktop/PatientPageDesktop";
import {PatientPageMobile} from "./Mobile/PatientPageMobile";
import {useNavigate, useParams} from "react-router-dom";
import {useDemo} from "../../Hooks/useDemo";
import {useTypes} from "../../Hooks/UseTypes";
import {ModalsCtx} from "../../Context/Modals.context";
import {useClients} from "../../Hooks/UseClients";
import {useModules} from "../../Hooks/UseModules";
import {useQuestionnaire} from "../../Hooks/UseQuestionnaire";
import {useAccount} from "../../Hooks/UseAccount";
import {useClinicalResearch} from "../../Hooks/useClinicalResearch";
import { usePriorities } from "../../Hooks/usePriorities";

// Patient Page
const PatientPage: FC<any> = React.memo(() => {
    let { deviceType } = useContext(AppCtx);
    const navigate = useNavigate();
    const {fetchClinicalResearches, clearClinicalResearch} = useClinicalResearch();
    const {resetClientsHealthPriorities} = usePriorities();

    const {setPersonEditOutputModalActive} = useContext(ModalsCtx);
    const {getGetClientICD10Codes, getClientScores} = useClients();
    const {fetchDemoClient, activeFlowId, activeClient, updateLocalFlowId, localFlowId, resetActiveClient} = useDemo(true, false, true);
    const {patientId} = useParams();
    const {fetchModules} = useModules();
    const {fetchTypes} = useTypes();
    const {HMSProjectId} = useAccount();
    const {fetchQuestionnaire} = useQuestionnaire();

    // Redirect to patients page if client not found
    useEffect(() => {
        if(activeClient.hasOwnProperty("clientID") && activeClient.clientID === 0) {
            console.log('xRESET REDIRECT');
            
            resetClientsHealthPriorities();
            navigate('/patients/');
        }
    }, [activeClient]);

    useEffect(() => {
        setPersonEditOutputModalActive(false);
        fetchDemoClient(patientId, localFlowId, HMSProjectId);
        getGetClientICD10Codes(patientId, localFlowId, '', HMSProjectId);
    }, [localFlowId, patientId, HMSProjectId]);

    useEffect(() => {
        fetchQuestionnaire(patientId, HMSProjectId)
        getClientScores(patientId, HMSProjectId);
    }, [HMSProjectId, patientId]);

    useEffect(() => {
        updateLocalFlowId(activeFlowId);
        fetchTypes();
        fetchModules();
    }, []);

    useEffect(() => {
        if(activeClient?.client?.SourceClientID === undefined) return;

        clearClinicalResearch();
        fetchClinicalResearches(activeClient?.client?.SourceClientID, HMSProjectId);
    }, [activeClient?.client?.SourceClientID, HMSProjectId]);

    // Did Mount
    useEffect(() => {
        return () => {
            console.log('xRESET UNMOUNT');
            resetActiveClient();
        }
    }, []);
    console.log('RENDER PATIENT PAGE', activeClient);
    
    return deviceType === 'desktop' ? <PatientPageDesktop/> : <PatientPageMobile/>;
});

export {PatientPage};
