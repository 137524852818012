import { FC, memo } from "react";
import { Row, Icon, TooltipWrapper, iconsTypes } from "lavaa";
import { useDemo } from "../../../../Hooks/useDemo";
import { IResultType } from "../../../../Redux/Slices/Demo/Demo.data";
import css from "./DiagnosisAndRisk.module.scss";

type IResultTypeMerged = IResultType & {
    riskLevel: string;
    tooltip: string;
    risk: number;
};

// Risk Level
const getRiskLevel = (risk: number): string => {
    if(risk <= 25) return 'no-risk';
    if(risk <= 50) return 'medium-risk';
    if(risk <= 75) return 'medium-high-risk';
    return 'high-risk';
};

// Merge Modules
const mergeModules = (modules: IResultType[]): IResultTypeMerged[] => {
    const mergedModules = modules.reduce((acc: Record<string, IResultTypeMerged>, item: IResultType) => {
        const iconKey = item.moduleIcon || 'stack';

        // If iconKey doesn't exist in accumulator, add it
        if (!acc[iconKey]) {
            acc[iconKey] = {
                ...item,
                risk: item.resultPercentage,
                riskLevel: getRiskLevel(item.resultPercentage),
                tooltip: item.moduleName
            };
        }

        // If iconKey exists in accumulator, merge items
        else {
            acc[iconKey].risk = Math.max(acc[iconKey].risk, item.resultPercentage);
            acc[iconKey].riskLevel = getRiskLevel(acc[iconKey].risk);
            acc[iconKey].tooltip = `${acc[iconKey].tooltip}, ${item.moduleName}`;
        } 
        
        return acc;
    }, {} as any);
    
    return Object.values(mergedModules);
};

// DiagnosisAndRisk Component
export const DiagnosisAndRisk: FC = memo(() => {

    // Hooks
    const { activeClient } = useDemo();

    // Vars
    const data: IResultType[] = activeClient?.results || [];
    const results: IResultType[] = data.map((item) => {
        return {
            ...item,
            moduleIcon: item.moduleIcon || 'stack',
            tooltip: item.moduleName,
            riskLevel: getRiskLevel(item.resultPercentage)
        };
    });

    const mergedResults = mergeModules(results);

    return (
        <Row className={css.DiagnosisAndRisk}>
            {
                mergedResults.map((item: IResultTypeMerged) => {
                    const icon = <Icon size="x2" name={iconsTypes[(item.moduleIcon as keyof typeof iconsTypes)]} />;

                    return <Row key={item.moduleUID} className={css.RiskIcon} data-risk={item.riskLevel}>
                        <TooltipWrapper tooltipText={item.tooltip} positionV="top" positionH="left" whiteSpace="pre">{icon}</TooltipWrapper>
                    </Row>
                })
            }
        </Row>
    )
});