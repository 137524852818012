import React, {FC} from "react";
import css from "./SurveysInfo.module.scss";
import {QuestionnairesPathway} from "../Cards/QuestionnairesPathway/QuestionnairesPathway.com";
import {LifeStyleCom} from "../Cards/LifeStyle/LifeStyle.com";
import {Col, Grid, Icon, iconsTypes, Row, Title} from "lavaa";

interface IProps {
}

// Surveys Info Content
const SurveysInfo: FC<IProps> = React.memo(() => {

    return (
        <Grid className={css.Grid} marginTop="2rem">
            <Col paddingLeft="2rem" paddingRight="2rem">
                <Row alignitems="center" marginBottom="2rem">
                    <Col className={css.HeaderIcon}>
                        <Icon name={iconsTypes.pathway} size="18"/>
                    </Col>
                    <Title size='x3'>Pathway</Title>
                </Row>
                <QuestionnairesPathway/>
            </Col>
            <div className={css.Delimeter}/>
            <Col paddingLeft="2rem" paddingRight="2rem">
                <Row alignitems="center" marginBottom="2rem">
                    <Col className={css.HeaderIcon}>
                        <Icon name={iconsTypes.lifeStyle} size="18"/>
                    </Col>
                    <Title size='x3'>Life Style</Title>
                </Row>
                <LifeStyleCom/>
            </Col>
        </Grid>
    )
});

export {SurveysInfo};
