import React, {FC} from "react";
import {iconsTypes} from "lavaa";
import {HealthBox} from "../../Cards/HealthBoxes/HealthBox.com";
import {useClinicalResearch} from "../../../../../Hooks/useClinicalResearch";

interface IProps {
    onCardClick?: (cardName: string) => void;
}

// Clinical Research Card
const ClinicalResearchCard: FC<IProps> = React.memo((props) => {
    
    const { onCardClick } = props;
    const {clinicalResearch} = useClinicalResearch();

    const title = "Clinical Trial";
    const icon = iconsTypes.clinical_research;

    const count = clinicalResearch?.length ?? 0;

    return (
        <HealthBox
            title={title}
            icon={icon}
            value={count}
			iconColor={"default"}
            cardName="ClinicalTrial"
            onCardClick={onCardClick}
        />
    )
});

export {ClinicalResearchCard};
