export type DashboardCardType = {
	key: string,
	title: string,
	type: 'chart' | 'report'
}

export const DashboardCards: {[key: string]: DashboardCardType} = {
	GeneralReport: {
		key: 'GeneralReport',
		title: 'Patient Care Prioritization Overview',
		type: 'report'
	},
	DischargeLast48HReport: {
		key: 'DischargeLast48HReport',
		title: 'Discharge last 48h - No Near Office Visit',
		type: 'report'
	},
	HighWorryScoreReport: {
		key: 'HighWorryScoreReport',
		title: 'High Worry Score - No Near Office Visit',
		type: 'report'
	},
	LongOverdueReport: {
		key: 'LongOverdueReport',
		title: 'Long Overdue - Medium Worry Score',
		type: 'report'
	},
	NoAwvThisYearReport: {
		key: 'NoAwvThisYearReport',
		title: 'No AWV this year - High Worry Score',
		type: 'report'
	},
	NoAwvThisYearLastYearReport: {
		key: 'NoAwvThisYearLastYearReport',
		title: 'No AWV 2024 and 2025',
		type: 'report'
	},
	DischargeTCMReport: {
		key: 'DischargeTCMReport',
		title: 'Transitional Care Management',
		type: 'report'
	},
	AdmissionTCMReport: {
		key: 'AdmissionTCMReport',
		title: 'Current Admissions',
		type: 'report'
	},
	AgeStat: {
		key: 'AgeStat',
		title: 'Age Statistics',
		type: 'chart'
	},
	GenderStat: {
		key: 'GenderStat',
		title: 'Gender Statistics',
		type: 'chart'
	},
	ModulesStat: {
		key: 'ModulesStat',
		title: 'Modules Statistics',
		type: 'chart'
	},
	PatientsStat: {
		key: 'PatientsStat',
		title: 'Patients\' Statistics',
		type: 'chart'
	},
	TotalAgeStat: {
		key: 'TotalAgeStat',
		title: 'Total Age Statistics',
		type: 'chart'
	},
	TotalGenderStat: {
		key: 'TotalGenderStat',
		title: 'Total Gender Statistics',
		type: 'chart'
	},
}

export const initialDashboardCardsView = [
	DashboardCards.DischargeLast48HReport.key,
	DashboardCards.HighWorryScoreReport.key,
	DashboardCards.LongOverdueReport.key,
	DashboardCards.NoAwvThisYearReport.key,
	DashboardCards.NoAwvThisYearLastYearReport.key,
	DashboardCards.DischargeTCMReport.key,
	DashboardCards.AdmissionTCMReport.key,
	DashboardCards.AgeStat.key,
	DashboardCards.ModulesStat.key,
	DashboardCards.GenderStat.key,
	DashboardCards.PatientsStat.key,
	DashboardCards.TotalAgeStat.key,
	DashboardCards.TotalGenderStat.key,
	DashboardCards.GeneralReport.key,
]