import React, {FC} from "react";
import {Col, Row, Text, Icon} from "lavaa";
import {useDemo} from "../../../../Hooks/useDemo";
import {patientPageTabs} from "../tabs";
import {classNames} from "../../../../Tools";
import {useMixPanel} from "../../../../Hooks/UseMixPanel";
import {PatientMenuInfo} from "./PatientMenuInfo.com";
import { DiagnosisAndRisk } from "../DiagnosisAndRisk/DiagnosisAndRisk.com";
import css from "./PatientPageMenu.module.scss";

interface Props {
    activeTab: number,
    setActiveTab: (index: number) => void,
}

// Patient Page Menu
const PatientPageMenu: FC<Props> = React.memo((props) => {

    // Props
    const {activeTab, setActiveTab} = props;

    const { activeClient } = useDemo();

    // Hooks
    const { track } = useMixPanel();

    const handleTabSwitch = (tab: any) => {
        const tabTitle = patientPageTabs[tab].title;
        setActiveTab(tab);
        track(tabTitle);
    };

    if ( !activeClient?.client ) return null;

    return (
        <Col className={css.Menu}>
            <Col className={css.MenuList}>
                {
                    patientPageTabs.map((tab, index) => {
                        const isActive = activeTab === index;
                        return (
                            <Row
                                key={index}
                                className={classNames(css.MenuItem, isActive ? css.active : "")}
                                onClick={() => handleTabSwitch(index)}
                            >
                                <Col className={css.MenuItemIcon}>
                                    <Icon name={tab.icon} size="x2"/>
                                </Col>
                                <Text size="x3" bold="true" className={css.MenuItemText}>{tab.title}</Text>
                            </Row>
                        )
                    })
                }
            </Col>

            {/* Personal Info Block */}
            <PatientMenuInfo/>

            {/* Diagnosis & Risk */}
            {/* <DiagnosisAndRisk /> */}
        </Col>
    )
});

export {PatientPageMenu};
