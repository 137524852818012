export const WorryScoreInfo: Record<number, string> = {
    1: 'due to the Low Raf Score and Costs',
    2: 'due to the Low Raf Score and Costs',
    3: 'due to the Lower Raf Score and Costs',
    4: 'due to the Lower Raf Score and Costs',
    5: 'due to the Raf Score and Costs',
    6: 'due to the Raf Score and Costs',
    7: 'due to the High Raf Score and Costs',
    8: 'due to the High Raf Score and Costs',
    9: 'ER Visit last 14 days',
    10: 'Hospital Admission/ Discharge last 14 days'
};

export const getWorryScoreInfo = (value: string | number): string => {
    return WorryScoreInfo[Number(value)] || '';
};