import { FC } from 'react';
import { Row } from 'lavaa';
import { Logo } from '../../Logo/Logo.component';
import { MainMenu } from '../../MainMenu/MainMenu.component';
import { MenuProfile } from '../../MenuProfile/MenuProfile.component';
import { MenuSearch } from "../../MenuSearch/MenuSearch.com";
import { LinkToDesktopVersion } from '../../LinkToDesktopVersion/LinkToDesktopVersion.component';
import css from './Header.module.scss';
import {MenuChooseProject} from "../../MenuChooseProject";

// Header
const Header: FC<any> = () => {
    return <Row className={ css.Header } shrink="0">

        {/* Logo */}
        <Logo/>

        {/* Main Menu */}
        <MainMenu/>

        {/* Menu Search */}
        <MenuSearch/>

        {/* Choose Project */}
        <MenuChooseProject/>

        {/* Profile */}
        <MenuProfile/>

        {/* Link To Desktop Version */}
        <LinkToDesktopVersion />
    </Row>;
};

export { Header };