import React, {FC} from "react";
import {CardItem} from "../Card/Card";
import {Vitals} from "../Cards/Vitals/Vitals.com";
import {ClinicResearch} from "../Cards/ClinicResearch/ClinicResearch.com";
import {iconsTypes, ScrollContainer} from "lavaa";
import {BasicInfo} from "../Cards/BasicInfo/BasicInfo.com";
import { PersonalInfo } from "../Cards/PersonalInfo/PersonalInfo.com";
import {HealthBoxes} from "../Cards/HealthBoxes/HealthBoxes.com";
import {usePatientPageDeviceType} from "../../../../Hooks/UsePatientPageDeviceType";
import css from "../Card/Card.module.scss";

interface IProps {
    onCardClick?: (cardName: string) => void;
}

// Health Card
const PersonalInfoPage: FC<IProps> = React.memo((props) => {
    const { onCardClick } = props;
    const viewType = usePatientPageDeviceType();
    const isTablet = viewType === 'tablet';
    console.log('viewType', viewType);
    
    return (
        <ScrollContainer>
            <div className={css.Grid}>
                
                {/* Personal Info Card */}
                <CardItem title="Personal Information" hasBorder={true} icon={iconsTypes.personOutline}>
                    {/* <PersonalInfo/> */}
                    <BasicInfo/>
                </CardItem>
                
                {/* Health Boxes */}
                <HealthBoxes size="doubled" onCardClick={onCardClick} />

                {/* Basic Info Card */}
                {/* <CardItem title="Personal Information" hasBorder={true} icon={iconsTypes.personOutline}>
                    <BasicInfo/>
                </CardItem> */}
                <CardItem title="Vitals" hasBorder={true} icon={iconsTypes.viruses}>
                    <Vitals/>
                </CardItem>
                
                <CardItem title="Clinical Trial" hSize={viewType === 'desktop' ? 'doubled' : 'default'} hasBorder={true} icon={iconsTypes.clinical_research}>
                    <ClinicResearch/>
                </CardItem>
                    

                {/* Vitals Card */}
                {/* <CardItem title="Vitals" hasBorder={true} icon={iconsTypes.viruses}>
                    <Vitals/>
                </CardItem> */}
                {/* {
                    isTablet && (
                        <CardItem title="Clinical Trial" hSize="doubled" hasBorder={true} icon={iconsTypes.clinical_research}>
                            <ClinicResearch/>
                        </CardItem>
                    )
                } */}
                {/*<CardItem title="Notes">*/}
                {/*</CardItem>*/}
            </div>
        </ScrollContainer>
    )
});

export {PersonalInfoPage};
