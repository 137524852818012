import React, {FC} from "react";
import {iconsTypes} from "lavaa";
import {HealthBox} from "../../Cards/HealthBoxes/HealthBox.com";
import {useDemo} from "../../../../../Hooks/useDemo";

interface IProps {
    onCardClick?: (cardName: string) => void;
}

// Weight Card
const WeightCard: FC<IProps> = React.memo((props) => {

    const { onCardClick } = props;
    const {activeClient} = useDemo();

    const title = "Weight";

    const personalRecords = activeClient?.client?.PersonalRecords ? activeClient?.client?.PersonalRecords[0] : null;
    const value = personalRecords?.Weight?.toFixed(0) ?? "–";

    return (
        <HealthBox
            title={title}
            icon={iconsTypes.weight}
            value={value}
			iconColor={"default"}
            cardName="Weight"
            onCardClick={onCardClick}
        />
    )
});

export {WeightCard};
