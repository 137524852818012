import React, {FC, useContext} from "react";
import {Col, Row, Text, Icon, iconsTypes, Button} from "lavaa";
import css from "./patientPageHeader.module.scss";
import {patientPageTabs} from "../tabs";
import {Link, useNavigate} from "react-router-dom";
import {useDemo} from "../../../../Hooks/useDemo";
import {ModalsCtx} from "../../../../Context/Modals.context";


interface Props {
	activeTab: number,
}

// Patient Page Header
const PatientPageHeader: FC<Props> = React.memo((props) => {

	const navigate = useNavigate();
	const { setMenuHealthBoxesModalActive } = useContext(ModalsCtx);

	const { activeClient } = useDemo();
	if (!activeClient?.client) return null;

	// Props
	const {activeTab} = props;
	const tabName = patientPageTabs[activeTab].title;
	const text = `Patient Page | ${tabName}`;
	const previousPage = "/patients";


	// On Go Back
	const goBack = () => {
		if(previousPage){
			navigate(previousPage);
			return;
		}
	};

	return (
		<Row className={css.Dashboard} alignitems="center" justifycontent="space-between">
			<Col>
				<Row>
					<Row paddingRight="0.25rem">
						<Col className={css.BackIcon} onClick={goBack}>
							<Icon name={iconsTypes.arrowBack} size={"x3"}/>
						</Col>
					</Row>
					<Text size="x6" bold={true} text={text}/>
				</Row>
				<Row marginTop="0.5rem" alignitems="center">
					<Link to={"/patients"} className={css.BreadcrumbsLink}>
						<Text size="x2">Patients Priorities</Text>
					</Link>
					<Col className={css.ArrowIcon} onClick={goBack}>
						<Icon name={iconsTypes.chevronRight} size={"x1"}/>
					</Col>
					<Text size="x2" className={css.BreadcrumbsText}>Patient page</Text>
					<Col className={css.ArrowIcon} onClick={goBack}>
						<Icon name={iconsTypes.chevronRight} size={"x1"}/>
					</Col>
					<Text size="x2" className={css.BreadcrumbsText}>{tabName}</Text>
				</Row>
			</Col>

			<Col>
				<Button type="primary" onClick={() => setMenuHealthBoxesModalActive(true)} icon={iconsTypes.gear} size={"small"}>Settings</Button>
			</Col>
		</Row>
	)
});

export {PatientPageHeader};
