import { createSlice } from '@reduxjs/toolkit';

interface IPrioritiesState {
  clientsPriorities?: any;
}

const initialState: IPrioritiesState = {

};

export const prioritiesSlice = createSlice({
  name: 'Priorities',
  initialState,
  reducers: {

    // Get Clients Health Priorities
    getClientsHealthPrioritiesAction: (state, action) => {},

    // Receive Clients Health Priorities
    receiveClientsHealthPrioritiesAction: (state, action) => {
      console.log('Receive Clients Health Priorities', action);
      const { payload } = action;

      const { data } = payload;
      if(!data) return;

      const {headers, rows, totalRows, totalPages } = data;
      if(!headers || !rows) return;

      const rowsPrepared = prepareRows(rows);

      state.clientsPriorities = {
        headers,
        rows: rowsPrepared,
        totalRows,
        totalPages,
        lastUpdated: performance.now()
      }
    },

    // Reset Clients Health Priorities
    resetClientsHealthPrioritiesAction: (state) => {
      state.clientsPriorities = {
        ...state.clientsPriorities,
        rows: [],
        totalRows: 0,
        totalPages: 0,
        lastUpdated: performance.now()
      }
    },
  }
});


function prepareRows(rows: any) {
  // Make all keys lowercase
  return rows.map((row: any) => lowerCaseKeys(row));

  function lowerCaseKeys(obj: any) {
    return Object.keys(obj).reduce((acc: any, key) => {
      acc[key.toLowerCase()] = obj[key];
      return acc;
    }, {});
  }
}

export const {
  getClientsHealthPrioritiesAction,
  receiveClientsHealthPrioritiesAction,
  resetClientsHealthPrioritiesAction
} = prioritiesSlice.actions;
export default prioritiesSlice.reducer;