import React, {FC, useState, useEffect, memo} from "react";
import {Col, Icon, iconsTypes, Row, Text} from "lavaa";
import css from "../RafScore.module.scss";
import {useClients} from "../../../../../Hooks/UseClients";
import {emptyMissingCodesData, prepareData, PreparedMissingCodesDataType} from "./util";
import {useStatic} from "../../../../../Hooks/UseStatic";
import {classNames} from "../../../../../Tools";

interface IProps {
    title: string,
    sourceDataKey: string,
    comparisonDataKeys: string[],
    emptyText: string
}

// Raf Score Missing
const RafScoreMissing: FC<IProps> = React.memo((props) => {
    const { sourceDataKey, comparisonDataKeys, title, emptyText } = props;

    const { clientScores } = useClients();
    
    const { ICD10Codes } = useStatic();
    const [preparedData, setPreparedData] = useState<PreparedMissingCodesDataType>(emptyMissingCodesData);

    useEffect(() => {
        const prepareDataLocal = prepareData(clientScores, sourceDataKey, comparisonDataKeys, ICD10Codes);
        setPreparedData(prepareDataLocal);
    }, [clientScores, sourceDataKey, comparisonDataKeys, ICD10Codes]);
    // console.log('clientScores', clientScores);
    
    
    return (
        <Col className={css.MissingCodesWrapper}>
            <Row className={css.MissingCodesHeader}>
                <Text size="x3" bold={true}>{title}</Text>
                <Text size="x3" bold={true} className={css.MissingCodesCost}>+ ${preparedData.cost.toFixed(0)}</Text>
            </Row>

            {
                preparedData.codes.length === 0 && (
                    <Row paddingTop="1.5rem" justifycontent="center">
                        <Text size="x2" bold={true} className={css.MissingCodesEmpty}>{emptyText}</Text>
                    </Row>
                )
            }

            <Row className={css.MissingCodes}>
                {
                    preparedData.codes.map((data, index) => {

                        return (
                            <Col className={classNames(css.MissingCode, !data.hasImpact ? css.NoImpact : '')} key={index}>
                                <Row className={css.MissingCodeHeader}>
                                    <Text size="x1" bold={true} ellipsis={true} maxLines={1} className={css.MissingCodeHeaderText}>{data.name}</Text>
                                </Row>
                                <Row className={css.MissingCodeVer}>
                                    <Icon name={iconsTypes.plusSquare} size="x3"/>
                                    <Text size="x3" bold={true}>V{data.model}</Text>
                                </Row>
                                <Row className={css.MissingCodeValue}>
                                    <Text size="x2" ellipsis={true} className={css.MissingCodeValueText}>HCC - <b>{data.hccCode}</b></Text>
                                </Row>
                                <Row className={css.MissingCodeValue}>
                                    <Text size="x2" ellipsis={true} className={css.MissingCodeValueText}>ICD - <b>{data.code}</b></Text>
                                </Row>
                                <Row className={css.MissingCodeValue}>
                                    <Text size="x2" ellipsis={true} className={css.MissingCodeValueText}>Score - <b>{data.score.toFixed(2)}</b></Text>
                                </Row>
                            </Col>
                        )
                    })
                }
            </Row>
        </Col>
    )
});

export {RafScoreMissing};
