import {FC, useContext, useEffect, useState} from "react";
import {Row, Grid, Loading} from 'lavaa';
import {IModuleType} from "../../../Redux/Slices/Demo/Demo.data";
import {PatientLabResultModal} from "../../Custom/Modals/PatientLabResultModal/PatientLabResultModal.component";
import {AppCtx} from "../../../Context/App.context";
import {PatientsListMob} from "../../PatientsList/PatientsListMob.component";
import {useDemo} from "../../../Hooks/useDemo";
import css from './Patients.module.scss';
import {PatientsListDesktop} from "../../PatientsList/Desktop/PatientsListDesktop.com";

// Patients
const Patients: FC<any> = () => {
    let {deviceType} = useContext(AppCtx);
    const [selectedModules, setSelectedModules] = useState<IModuleType[]>([]);
    
    // Clients load watcher
    const { clientsLoading, activeClient, clearClient } = useDemo();

    // On Module Select
    const onModuleSelect = (data: IModuleType[]) => {
        setSelectedModules(data);
    };

    // Clear client if not found
    useEffect(() => {
        if(activeClient.hasOwnProperty("client") && activeClient.client === null) {
            clearClient();
        }
    }, [activeClient]);

    if (deviceType !== "mobile") {
        return (
            <>
                <Row grow="1" className={css.Patients}>
                    <Grid className={css.PatientsList}>
                        <PatientsListDesktop/>
                    </Grid>

                    {/* Loading */}
                    {
                        clientsLoading === false ? null :
                        <Row className={css.Loading} alignitems="center" justifycontent="center">
                            <Loading/>
                        </Row>
                    }
                </Row>

                {/* Modals */}
                <PatientLabResultModal/>
            </>
        );
    }

    return (
        <>
            {/*<DemoFilter/>*/}
            <PatientsListMob onModuleSelect={onModuleSelect} modules={selectedModules} clientsLoading={clientsLoading}/>

            {/* Modals */}
            <PatientLabResultModal/>
        </>
    );

};

export {Patients};