import React, {FC} from "react";
import css from "../Card/Card.module.scss";
import {CardItem} from "../Card/Card";
import {HealthBoxes} from "../Cards/HealthBoxes/HealthBoxes.com";
import {SurveysInfo} from "./SurveysInfo.com";
import {Grid, iconsTypes} from "lavaa";

interface IProps {
    onCardClick?: (cardName: string) => void;
}

// Surveys Page
const SurveysPage: FC<IProps> = React.memo((props) => {

    const { onCardClick } = props;

    return (
        <Grid className={css.Grid} data-type="double">
            <HealthBoxes size="full-width" onCardClick={onCardClick} />
            <CardItem hSize="doubled" size="doubled" title="Surveys" icon={iconsTypes.queryStats} hasBorder={true}>
                <SurveysInfo/>
            </CardItem>
        </Grid>
    )
});

export {SurveysPage};
