import React, {FC} from "react";
import {Col, Icon, Row, Text, Title} from "lavaa";
import css from "./HealthBoxes.module.scss";

export type HealthBoxIconColor = 'default' | 'danger' | 'success' | 'info';

export type HealthBoxProps = {
	title: string;
	icon: number;
	value: string | number;
	subValue?: string;
	iconColor?: HealthBoxIconColor;
	cardName: string;
	onCardClick?: (cartName: string) => void;
}

const HealthBox: FC<HealthBoxProps> = React.memo((props) => {

	const { title, icon, value, subValue, iconColor, cardName, onCardClick } = props;

	return (
		<Col className={css.Box} justifycontent="space-between" grow="1" onClick={() => onCardClick && onCardClick(cardName)} data-clickable={!!onCardClick}>
			<Title size="x3">{title}</Title>
			<Row alignitems="center">
				<Col className={css.Icon} data-color={iconColor}>
					<Icon name={icon} size="x4"/>
				</Col>
				<Col marginLeft="0.5rem" className={css.ValueWrapper}>
					<Text size="x5" bold={true} ellipsis={true} className={css.Value}>{value}</Text>
					{
						subValue && <Text size="x1" className={css.SubValue} ellipsis={true}>{subValue}</Text>
					}
				</Col>
			</Row>
		</Col>
	)
});

export {HealthBox};
