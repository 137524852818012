import React, {FC} from "react";
import css from "./TreatmentPage.module.scss";
import {Cell, Col, Row, Table, TableColumn, TableText, Title} from "lavaa";
import {Treatment} from "./treatment";
import {useDemo} from "../../../../Hooks/useDemo";
import {IClientGuidelinesType, IResultType} from "../../../../Redux/Slices/Demo/Demo.data";

interface IProps {
    filter: string
}

// Guideline By Priority
const TreatmentByPriority: FC<IProps> = React.memo((props) => {

    const {filter} = props;
    const {activeClient, activeClientGuidelines} = useDemo();
    console.log('activeClientGuidelines', activeClientGuidelines);
    
    const columns: TableColumn[] = [
        {id: 'name', label: 'Guideline Name'},
        {id: 'diagnoses', label: 'Diagnoses'},
    ];

    function sortingHandler(column: string, asc: boolean) {
        console.log(column, asc);
    }

    let guidelines;
    switch (filter) {
        case "checked":
            guidelines = activeClientGuidelines?.filter((guideline: IClientGuidelinesType) => guideline.isChecked);
            break;
        case "unchecked":
            guidelines = activeClientGuidelines?.filter((guideline: IClientGuidelinesType) => !guideline.isChecked);
            break;
        default:
            guidelines = activeClientGuidelines;
            break;
    }

    const rows: any = [];
    console.log('guidelines', guidelines);

    if(!guidelines) return null;

    guidelines?.map((guideline: IClientGuidelinesType) => {
        const results = activeClient?.results.filter((result: IResultType) => guideline.modules.includes(result.moduleUID));
        const modulesNames = results?.map((result: IResultType) => result.moduleName);
        const modulesNamesString = modulesNames?.join(", ");
        rows.push([
            <Cell alignitems="center" className={css.Cell} key={guideline.guidelineUID}>
                <Treatment guideline={guideline}/>
            </Cell>,
            <TableText maxLines={2}>{modulesNamesString}</TableText>,
        ]);
    });
    console.log('guidelines rows', rows);

    return (
        <Col grow="1" style={{minHeight: '200px'}}>
            {/* Title */}
            <Row style={{whiteSpace: 'nowrap'}} marginBottom="1.75rem">
                <Title size='x4'>Insights by Priority</Title>
            </Row>
            <Row grow="1">
                <Table columns={columns} rows={rows} style="lines" onSorting={sortingHandler}/>
            </Row>
        </Col>
    )
});

export {TreatmentByPriority};
