import React, {FC} from "react";
import css from "./style.module.scss";
import {Col, Row, Text} from "lavaa";
import { QuestionnaireField } from "../../Questionnaire/field";
import {IQuestionnaireType} from "../../../../Redux/Slices/Questionnaire/Questionnaire.data";
import {useDemo} from "../../../../Hooks/useDemo";
import {classNames} from "../../../../Tools";
import {IModuleType} from "../../../../Redux/Slices/Demo/Demo.data";

interface IProps {
    filter: string,
    inputs: any,
    onChange: (field: string, data: any) => void,
    questionnaireData: IQuestionnaireType[],
}

// Questionnaire Fields
const QuestionnaireFields: FC<IProps> = React.memo((props) => {
    const {filter, inputs, onChange, questionnaireData} = props;
    const { modules } = useDemo();
    const isAll = filter === 'all';

    if(questionnaireData.length === 0) return(
        <Row>
            <Text size="x4">Data is completed. Come back later.</Text>
        </Row>
    );

    if(isAll){
        return(
            <Col className={classNames(css.ModuleBox, css.Container)}>
                {
                    questionnaireData.map((question: IQuestionnaireType, num) => {
                        const id = question.Parameter.ParameterID.toString();
                        const value = inputs[id] ?? undefined;
                        return (
                            <QuestionnaireField question={question} value={value} num={num} onChange={onChange} key={id}/>
                        )
                    })
                }
            </Col>
        )
    }

    const module = modules.find((module: IModuleType) => module.ModuleUID === filter);
    const moduleName = module?.ModuleName ?? "";
    const moduleUID = module?.ModuleUID ?? "";

    const questionnaireFilteredData = questionnaireData.filter((row: IQuestionnaireType) => row.ModuleUIDs.includes(moduleUID));

    const count = questionnaireFilteredData.length;

    return(
        <Col className={classNames(css.ModuleBox, css.Container)}>
            <Row className={css.Module} alignitems="center" justifycontent="space-between">
                <Row>
                    <Row marginRight="0.25rem"><Text size="x5" className={css.ModuleTitle}>Module:</Text></Row>
                    <Text size="x5" bold={true} className={css.ModuleTitle}>{moduleName}</Text>
                </Row>
                <Row marginRight="1.25rem">
                    <Text size="x3">{count} Question{count != 1 ? 's' : ''}</Text>
                </Row>
            </Row>
            {
                (count === 0) && (
                    <Row>
                        <Text size="x4">Module data is completed. Come back later.</Text>
                    </Row>
                )
            }
            <Col>
                {
                    questionnaireFilteredData.map((question: IQuestionnaireType, num) => {
                        const id = question.Parameter.ParameterID.toString();
                        const value = inputs[id] ?? undefined;
                        return (
                            <QuestionnaireField
                                question={question}
                                value={value}
                                onChange={onChange}
                                key={id}
                                num={num}
                            />
                        )
                    })
                }
            </Col>
        </Col>
    )

});

export {QuestionnaireFields};
