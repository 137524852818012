import React, {FC, useContext} from "react";
import {Col, Row, Text, Icon, iconsTypes, Button, addSnackbar} from "lavaa";
import {useDemo} from "../../../../Hooks/useDemo";
import {copyToClipboard} from "../../../../Tools/clipboard";
import {ModalsCtx} from "../../../../Context/Modals.context";
import { DiagnosisAndRisk } from "../DiagnosisAndRisk/DiagnosisAndRisk.com";
import css from "./PatientPageMenu.module.scss";

interface Props {
}

const reimbursementCareModelText: any = {
    '0': 'Unknown',
    '1': 'Fee for service',
    '2': 'Value based'
};

// Patient Menu Info Block
const PatientMenuInfo: FC<Props> = React.memo((props) => {

    const { activeClient } = useDemo();
    const {setReportClientInfoModalActive, setReportClientInfoId, setReportSourceClientInfoId} = useContext(ModalsCtx);
    if ( !activeClient?.client ) return null;

    if (
        !activeClient?.client
    ) return null;


    const personalRecords = (!activeClient?.client?.PersonalRecords || !activeClient?.client?.PersonalRecords.length) ? {} : activeClient?.client?.PersonalRecords[0];

    const firstName = (activeClient.client?.FirstName ?? "").trim();
    const lastName = (activeClient.client?.LastName ?? "").trim();

    const clientId = activeClient.client.SourceClientID;
    let gender = "Unknown";
    if(activeClient?.client?.Gender == "F"){
        gender = "Female";
    }
    if(activeClient?.client?.Gender == "M"){
        gender = "Male";
    }

    function openClientInfo() {
        console.log("openClientInfo", activeClient.clientID, clientId);
        setReportClientInfoId(activeClient.clientID);
        setReportSourceClientInfoId(clientId);
        setReportClientInfoModalActive(true);
    }

    // Get ReimburseCareModel
    const reimburseCareModel = personalRecords?.ReimburseCareModel ?? "–";

    // Get PCP
    const clinicParameters = activeClient?.client?.ClinicParameters ? activeClient?.client?.ClinicParameters[0] : null;
    const pcp = clinicParameters?.PrimaryPhysician ?? "–";

    // Get Care Provider
    const insuranceRecords = activeClient?.client?.InsuranceRecords ? activeClient?.client?.InsuranceRecords[0] : null;
    const careProvider = insuranceRecords?.PrimaryCareProvider ?? "–";

    // Handle Copy
    async function handleCopy (value: string) {
        await copyToClipboard(value);
        addSnackbar('success', 'Copied!', '');
    }

    return (
        <Col className={css.Widget}>
            <Row justifycontent="space-between">
                <Row alignitems="center">
                    <Col className={css.UserIcon} alignitems="center" justifycontent="center">
                        <Icon name={iconsTypes.person} size="x2"/>
                    </Col>
                    <Col marginLeft="1rem">
                        <Text size="x2" bold={true}>{firstName}</Text>
                        <Text size="x2" bold={true}>{lastName}</Text>
                    </Col>
                </Row>
                <Button icon={iconsTypes.phone} type="primary" size="small" iconSize="18" className={css.UserInfoButton} onClick={openClientInfo}/>
            </Row>
            <Col className={css.UserInfoList}>
                <Row className={css.UserInfoParam} alignitems="center">
                    <Text size="x2" className={css.UserInfoParamLabel}>ID:</Text>
                    <Row alignitems="center">
                        <Text size="x2" className={css.UserInfoParamValueText} ellipsis={true}>{clientId}</Text>
                        <Col className={css.UserInfoParamValueIcon} onClick={() => handleCopy(clientId)}>
                            <Icon name={iconsTypes.copy} size="x1"/>
                        </Col>
                    </Row>
                </Row>
                <Row className={css.UserInfoParam} alignitems="center">
                    <Text size="x2" className={css.UserInfoParamLabel}>Gender:</Text>
                    <Text size="x2" bold={true} className={css.UserInfoParamValueText} ellipsis={true}>{gender}</Text>
                </Row>
                <Row className={css.UserInfoParam} alignitems="center">
                    <Text size="x2" className={css.UserInfoParamLabel}>Age:</Text>
                    <Text size="x2" bold={true} className={css.UserInfoParamValueText} ellipsis={true}>{activeClient.client.FullYears}</Text>
                </Row>
                <Row className={css.UserInfoParam} alignitems="center">
                    <Text size="x2" className={css.UserInfoParamLabel}>Reimbursement Care Model:</Text>
                    <Text size="x2" bold={true} className={css.UserInfoParamValueText} ellipsis={true}>{reimbursementCareModelText[reimburseCareModel] || '-'}</Text>
                </Row>
                <Row className={css.UserInfoParam} alignitems="center">
                    <Text size="x2" className={css.UserInfoParamLabel}>PCP:</Text>
                    <Text size="x2" bold={true} className={css.UserInfoParamValueText} ellipsis={true}>{pcp}</Text>
                </Row>
                <Row className={css.UserInfoParam} alignitems="center">
                    <Text size="x2" className={css.UserInfoParamLabel}>Primary Care Provider:</Text>
                    <Text size="x2" bold={true} className={css.UserInfoParamValueText} ellipsis={true}>{careProvider}</Text>
                </Row>

                <DiagnosisAndRisk />
            </Col>
        </Col>
    )
});

export {PatientMenuInfo};
