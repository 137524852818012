import {useContext, useEffect} from 'react';
import mixpanel from 'mixpanel-browser';
import { RoutesProvider } from './Components/Routes/RoutesProvider.component';
import { useTheme } from './Hooks/UseTheme';
import { useAuth } from './Hooks/UseAuth';
import { LoadingFullScreen, Snackbar } from 'lavaa';
import { useDemo } from "./Hooks/useDemo";
import { useClients } from "./Hooks/UseClients";
import { AppCtx } from "./Context/App.context";
import { DeviceType } from "./Context/App.types";
import { useLocation } from 'react-router-dom';
import { Extension } from './Components/Extension/Extension.component';
import { useHelpCenter } from './Hooks/UseHelpCenter';
import { useAccount } from './Hooks/UseAccount';
import { useMixPanel } from './Hooks/UseMixPanel';
import 'primereact/resources/themes/md-light-deeppurple/theme.css';
import 'primereact/resources/primereact.min.css';
import './App.css';
import { useUserActivity } from './Hooks/UseUserActivity';
import { setOnReconnectCallback } from './Redux/Middleware/Connector';

function App() {

    const location: any = useLocation();
    const { theme, applyTheme } = useTheme();
    const { loadAccount, isAuth } = useAuth(true);
    const { getClientScheme } = useClients();
    let { setDeviceType } = useContext(AppCtx);
    const { fetchHelpCenterData } = useHelpCenter();
    const { account, getAccountDisplayName, logoutAccount } = useAccount();
    const { track } = useMixPanel();

    // Demo modules watcher
    useDemo(false, false, false, true);

    // Is Extension Page
    const isExtensionSyncPage = location.pathname.includes('/extension/');

    // Init
    const init = () => {
        // loadTheme();
        applyTheme(theme);
        loadAccount();
    };

    // Load Data
    const loadData = () => {
        getClientScheme();
        fetchHelpCenterData(2);
    };
    
    function checkDeviceType() {
        const width = window.innerWidth;
        let deviceType:DeviceType = 'desktop';
        if(width <= 768) {
            deviceType = 'mobile';
        }
        else if(width <= 1200) {
            deviceType = 'tablet';
        }
        setDeviceType(deviceType);
    }

    function setDeviceHeight(){
        document.documentElement.style.setProperty('--vh', `${window.innerHeight * 0.01}px`);
    }

    useEffect(() => {

        if (isExtensionSyncPage) return;

        init();
        checkDeviceType();
        setDeviceHeight();
        window.addEventListener('resize', function (){
            checkDeviceType();
            setDeviceHeight();
        });

        return () => {
            window.removeEventListener('resize', function (){
                checkDeviceType();
                setDeviceHeight();
            });
        }
    }, []);

    // Socket Reconnect Callback
    useEffect(() => {
        setOnReconnectCallback(loadAccount);
        return () => {
            setOnReconnectCallback(null);
        };
    }, []);

    useEffect(() => {
        if(!isAuth) return;
        loadData();
    }, [isAuth]);

    // Track Login Mixpanel
    useEffect(() => {
        if(!isAuth || !account.name) return;
        if (mixpanel.hasOwnProperty('config') === true) {
            mixpanel.identify(account.name);
            mixpanel.people.set_once({
                'name': getAccountDisplayName(),
                'Register Source': 'HMS'
            });

            mixpanel.register({
                "Source": "HMS"
            });
            track('Login');
        }
    }, [isAuth, account]);

    // Init User Activity Watcher
    useUserActivity(logoutAccount);

    // console.enableLog
    useEffect(() => {
        (console as any).enableLog = false;

        const originalConsoleLog = console.log;

        console.log = (...args) => {
            if ((console as any).enableLog) {
              originalConsoleLog(...args);
            }
        };

        return () => {
            console.log = originalConsoleLog;
        };
    }, []);

    // Loader
    if(isAuth === null && isExtensionSyncPage === false) {
        return (
            <LoadingFullScreen/>
        )
    }

    // Routes Provider
    return <>
        <RoutesProvider/>
        <Snackbar/>
        <Extension />
    </>
}

export default App;