import { Cell, Row, Col, withoutPaddings, Icon, iconsTypes, Text } from 'lavaa';
import { useAccount } from '../../Hooks/UseAccount';
import { MenuProfileDropdown } from './MenuProfileDropdown.component';
import { useState } from 'react';
import css from './MenuProfile.module.scss';

// Menu Profile
const MenuProfile = () => {

    //States
    const [dropdownActive, setDropdownActive] = useState(false);

    // Hooks
    const { account } = useAccount();

    function clickHandler(){
        setDropdownActive((prev) => !prev);
    }

    // Display Name
    const displayName = (account: any): string => {
        const userAdditionalName = account.details && account.details.length ? account.details.find((item: any) => item.name === 'DisplayName') : '';
        return userAdditionalName?.value ?? account.name;
    };
    
    return (
        <Row justifycontent="end" className={css.MenuProfileComponent}>
            <Row className={css.MenuProfileWrapper}>
                <Row alignitems="center" className={css.MenuProfile} onClick={clickHandler}>
                    
                    {/* User Icon */}
                    <Cell alignitems='center' justifycontent='center' className={css.ProfileIcon}>
                        <Icon name={iconsTypes.person}/>
                    </Cell>

                    {/* User Name */}
                    <Col className={css.ProfileInfo}>
                        
                        {/* Name */}
                        <Cell {...withoutPaddings}>
                            <Text text={displayName(account)} ellipsis={true} size='x3' bold={true} className={css.ProfileName}/>
                        </Cell>

                        {/* Position ??? */}
                        <Cell {...withoutPaddings}>
                            <Text text={account.position} ellipsis={true} size='x2' className={css.ProfilePosition}/>
                        </Cell>

                        {/* Powered by */}
                        <Cell className={css.PoweredBy} {...withoutPaddings}>
                            <Text text={account.poweredBy } ellipsis={true} size='x1' className={css.PoweredByText}/>
                        </Cell>
                    </Col>

                    {/* Arrow */}
                    <Cell alignitems='center' justifycontent='center'  {...withoutPaddings} paddingLeft='1rem' className={css.ArrowIcon}>
                        <Icon name={iconsTypes.arrowDown}/>
                    </Cell>
                </Row>

                {
                    /* Dropdown */
                    dropdownActive && <MenuProfileDropdown closeHandler={() => setDropdownActive(false)}/>
                }
            </Row>
        </Row>
    );
};

export { MenuProfile };