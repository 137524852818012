import { FC } from "react";
import {Col, Text, Row, Checkbox, Grid, Cell, Icon, iconsTypes} from 'lavaa';
import {MenuHealthBoxes} from "./MenuHealthBoxes.type";
import css from "./HealthBoxes.module.scss";


interface IProps {
    menuWidgetsLocal: MenuHealthBoxes[],
    setMenuWidgetsLocal: (data: MenuHealthBoxes[]) => void
}

// Menu Health Boxes Content
const MenuHealthBoxesContent: FC<IProps> = (props) => {

    const {menuWidgetsLocal, setMenuWidgetsLocal} = props;

    function checkHandler(key: MenuHealthBoxes, value:boolean) {
        if (!value) {
            setMenuWidgetsLocal(menuWidgetsLocal.filter((item) => item !== key));
        } else {
            setMenuWidgetsLocal([...menuWidgetsLocal, key]);
        }
    }

    function showItem(value: MenuHealthBoxes, index: number | string){

        const isChecked = menuWidgetsLocal.includes(value);
        const hasArrow = isChecked && index != 0;

        function changeHandler(changeValue:boolean) {
            checkHandler(value, changeValue);
        }

        function pushUpHandler(){
            if(!hasArrow) return;
            const index = menuWidgetsLocal.indexOf(value);
            const local = [...menuWidgetsLocal];
            local.splice(index, 1);
            local.splice(index - 1, 0, value);
            setMenuWidgetsLocal(local);
        }

        return (
            <Row key={value}>
                <Cell grow="1">
                    <label htmlFor={ `widget_${index}` } className={css.Label}>
                        <Row>
                            <Cell paddingRight="0.5rem">
                                <Checkbox
                                    id={`widget_${index}`}
                                    checked={isChecked}
                                    onChange={(changeValue) => changeHandler(changeValue)}
                                />
                            </Cell>

                            <Text size="x3" className={css.Text}>{value}</Text>
                        </Row>
                    </label>
                </Cell>

                {
                    hasArrow && (
                        <Cell paddingLeft="0.5rem" className={css.ArrowUp} onClick={pushUpHandler}>
                            <Icon name={iconsTypes.arrowLongUp}/>
                        </Cell>
                    )
                }
            </Row>
        );
    }


    return (
        <Col>
            <Row paddingTop="0.5rem" paddingBottom="0.5rem" marginBottom="0.75rem">
                <Text size="x4" bold={true}>Highlights</Text>
            </Row>


            <Grid className={css.List}>
                {
                    menuWidgetsLocal.map((value, index) => (
                        showItem(value, index)
                    ))
                }
                {
                    Object.entries(MenuHealthBoxes).map(([key, value]) => {
                        if(menuWidgetsLocal.includes(value)) {
                            return null;
                        }
                        else{
                            return showItem(value, key);
                        }
                    })
                }
            </Grid>
        </Col>
    )
};

export { MenuHealthBoxesContent };