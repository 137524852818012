import React, {FC} from 'react';
import {Row, Col, Text, iconsTypes, Icon, Cell, addSnackbar, Checkbox} from 'lavaa';
import css from './ClinicResearch.module.scss'
import {useClinicalResearch} from "../../../../../Hooks/useClinicalResearch";
import {IClinicalResearchType} from "../../../../../Redux/Slices/ClinicalResearch/ClinicalResearch.data";
import {useModules} from "../../../../../Hooks/UseModules";

interface IProps {
}

const ClinicResearch: FC<IProps> = () => {

    const {getModuleTemplate} = useModules();
    const {clinicalResearch, updateClinicalResearchStatus} = useClinicalResearch();

    // console.log('clinicalResearchx', clinicalResearch);
    
    if(!clinicalResearch || clinicalResearch.length === 0) {
        return (
            <Text size="x3" className={css.Empty}>No Clinical Trial Data Available</Text>
        )
    }

    function changeStatusHandler(item: IClinicalResearchType) {
        const { ModuleUID, ClientID, ProjectUID, IsApproved } = item;
        
        updateClinicalResearchStatus(ModuleUID, ClientID, ProjectUID, !IsApproved);

        // Show snackbar
        if (IsApproved) {
            addSnackbar('warning', 'Success!', 'The clinical trial is back to approve');
        } else {
            addSnackbar('success', 'Success!', 'Client was approved for clinical trial');
        }
    }

    return (
        <Col>
            <Row className={css.Header} justifycontent="space-between" alignitems="center">
                <Text size="x3" bold={true}>Applicable Clinical Trials</Text>
                <Row className={css.ColApproval} justifycontent="center">
                    <Text size="x3" bold={true}>Patient’s Approval</Text>
                </Row>
            </Row>

            {/* List of Clinical Trials */}
            <Col className={css.List}>
                {
                    clinicalResearch.map((item: IClinicalResearchType, i: number) => {
                        const module = getModuleTemplate(item.ModuleUID);
                        if(!module) return null;

                        const isApproved = item.IsApproved;
                        return (
                            <Row className={css.Item} alignitems="center" justifycontent="space-between" key={i}>
                                <Row className={css.Name} paddingLeft="1rem" paddingRight="1rem" alignitems="center">
                                    <Text size="x2">
                                        {module.name}
                                    </Text>
                                </Row>
                                <Col className={css.Info} alignitems="center" shrink="0">
                                    {
                                        isApproved ? (
                                            // <Cell className={css.ApprovedIcon}>
                                            //     <Icon name={iconsTypes.checkCircleOutline} size="x1"/>
                                            // </Cell>
                                            <Checkbox checked={isApproved} onChange={() => changeStatusHandler(item)}/>
                                        ) : (
                                            <Checkbox checked={isApproved} onChange={() => changeStatusHandler(item)}/>
                                        )
                                    }
                                </Col>
                            </Row>
                        );
                    })
                }
            </Col>
        </Col>
    )
};

export {ClinicResearch};
