import React, {FC} from "react";
import {iconsTypes} from "lavaa";
import {HealthBox, HealthBoxIconColor} from "../../Cards/HealthBoxes/HealthBox.com";
import {useDemo} from "../../../../../Hooks/useDemo";

interface IProps {
    onCardClick?: (cardName: string) => void;
}

// Blood Pressure Card
const BloodPressureCard: FC<IProps> = React.memo((props) => {

    const { onCardClick } = props;
    const {activeClient} = useDemo();

    const title = "Blood Pressure";

    const personalRecords = activeClient?.client?.PersonalRecords ? activeClient?.client?.PersonalRecords[0] : null;
    const systolic = personalRecords?.SystolicBloodPressure ?? "–";
    const diastolic = personalRecords?.DiastolicBloodPressure ?? "–";

    const value = `${systolic}/${diastolic}`;

    let color:HealthBoxIconColor = "default";
    if(systolic >= 140 || diastolic >= 90){
        color = "danger";
    }
    else if(systolic < 140 && diastolic < 90){
        color = "success";
    }

    return (
        <HealthBox
            title={title}
            icon={iconsTypes.bloodPressure}
            value={value}
			iconColor={color}
            cardName="BloodPressure"
            onCardClick={onCardClick}
        />
    )
});

export {BloodPressureCard};
