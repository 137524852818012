import React, { FC } from "react";
import {Col, Row, Text} from "lavaa";
import { useDemo } from "../../../../../Hooks/useDemo";
import { useClients } from "../../../../../Hooks/UseClients";
import { convertValueWithScheme } from "../../../../../Tools/schemeData";
import css from "./BasicInfo.module.scss";
import {classNames} from "../../../../../Tools";

interface IData {
  label: string,
  value: string,
}

const BasicInfo: FC = React.memo(() => {

  const { activeClient } = useDemo();
  const { clientScheme } = useClients();

  if (
      !activeClient?.client
      || !activeClient?.client?.PersonalRecords || !activeClient?.client?.PersonalRecords.length
      || !clientScheme?.PersonalRecords
  ) return null;

  const personalRecords = activeClient?.client?.PersonalRecords[0];

  const data: IData[] = [];
  Object.entries(personalRecords).forEach(([key, value]) => {
    const scheme = clientScheme.PersonalRecords.subfields[key];
    if(!scheme) return;

    data.push(
        {
          label: scheme.label,
          value: convertValueWithScheme(scheme, value),
        }
    )
  });

  console.log(personalRecords);

  return (
      <Col className={css.List} marginTop="1rem">
        {
          data.map( ( item: any, index: number ) => (
            <Row key={index} className={classNames(css.Item, index >= data.length - 2 ? css.Last : "")}>
              <Text text={item.label} size="x2" ellipsis={true} className={css.ItemLabel}/>
              <Text text={item.value} size="x2" className={css.ItemValue} bold={true}/>
            </Row>
          ) )
        }
      </Col>
  )
});

export { BasicInfo };
