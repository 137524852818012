export type PreparedCode = {
	name: string,
	code: string,
	hccCode: string,
	model: '24' | '28',
	score: number,
	hasImpact: boolean
}

export type PreparedMissingCodesDataType = {
	codes: PreparedCode[],
	cost: number
}

export const emptyMissingCodesData: PreparedMissingCodesDataType = {
	codes: [],
	cost: 0
}


export function prepareData(data: any, sourceKey: string, comparisonKeys: string[], ICD10Codes: any): PreparedMissingCodesDataType {

	const sourceData = data[sourceKey];
	if (!sourceData) return emptyMissingCodesData;

	const sourcePreparedCodes = prepareCodes(sourceData, ICD10Codes);

	// Collect source and comparison HCC codes
	const { hccCodesV24: sourceHccCodesV24, hccCodesV28: sourceHccCodesV28 } = collectSourceHccCodes(sourceData);
    const { hccCodesV24: comparisonHccCodesV24, hccCodesV28: comparisonHccCodesV28 } = collectComparisonHccCodes(data, comparisonKeys);

	// Collecting data from all comparisonKeys
    const comparisonPreparedCodes: PreparedCode[] = [];
    comparisonKeys.forEach((key) => {
        if (data[key]) {
            comparisonPreparedCodes.push(...prepareCodes(data[key], ICD10Codes));
        }
    });

	const missingCodes = findMissingCodes(sourcePreparedCodes, comparisonPreparedCodes)
		.sort((a, b) => {
			// Sort by impact
			if(a.hasImpact && !b.hasImpact) return -1;
			if(!a.hasImpact && b.hasImpact) return 1;

			// Sort by code
			return a.code.localeCompare(b.code);
		});

	// Calculate cost
	const totalScore = calcTotalScore(sourceHccCodesV24, sourceHccCodesV28, comparisonHccCodesV24, comparisonHccCodesV28);

	return {
		codes: missingCodes,
		cost: totalScore
	}
}

// Collect unique HCC codes from source data
function collectSourceHccCodes(sourceData: any) {
    const hccCodesV24 = new Map<string, number>(); // Set to store unique HCC codes for model 24
    const hccCodesV28 = new Map<string, number>(); // Set to store unique HCC codes for model 28

    if (!sourceData || !sourceData.hcCs) return { hccCodesV24, hccCodesV28 };

    sourceData.hcCs.forEach((hcC: any) => {
        const model = hcC.engineResult.model;

        // Validate model type (only 24 and 28 are considered)
        if (!['24', '28'].includes(model)) return;

        hcC.hccScores.forEach((hccScore: any) => {
			const {hccCode, score} = hccScore;

            if (model === '24') {
                hccCodesV24.set(hccCode, score);
            } else if (model === '28') {
                hccCodesV28.set(hccCode, score);
            }
        });
    });

    return { hccCodesV24, hccCodesV28 };
}

// Collect unique HCC codes from comparison data
function collectComparisonHccCodes(data: any, comparisonKeys: string[]) {
    const hccCodesV24 = new Set<string>(); // Set to store unique HCC codes for model 24
    const hccCodesV28 = new Set<string>(); // Set to store unique HCC codes for model 28

    comparisonKeys.forEach((key) => {
        const comparisonData = data[key];
        if (!comparisonData || !comparisonData.hcCs) return;

        comparisonData.hcCs.forEach((hcC: any) => {
            const model = hcC.engineResult.model;

            // Validate model type (only 24 and 28 are considered)
            if (!['24', '28'].includes(model)) return;

            hcC.hccScores.forEach((hccScore: any) => {
                if (model === '24') {
                    hccCodesV24.add(hccScore.hccCode);
                } else if (model === '28') {
                    hccCodesV28.add(hccScore.hccCode);
                }
            });
        });
    });

    return { hccCodesV24, hccCodesV28 };
}

// Calculate total score
function calcTotalScore(
	sourceHccCodesV24: Map<string, number>, 
    sourceHccCodesV28: Map<string, number>, 
    comparisonHccCodesV24: Set<string>, 
    comparisonHccCodesV28: Set<string>
): number {
	let sumScoresV24 = 0;
	let sumScoresV28 = 0;

	// Sum up scores from V24, but only if they are NOT in comparisonHccCodesV24
	sourceHccCodesV24.forEach((score, hccCode) => {
		if (!comparisonHccCodesV24.has(hccCode)) {
			sumScoresV24 += score;
		}
	});
	
	// Sum up scores from V28, but only if they are NOT in comparisonHccCodesV28
	sourceHccCodesV28.forEach((score, hccCode) => {
		if (!comparisonHccCodesV28.has(hccCode)) {
			sumScoresV28 += score;
		}
	});
	
	// Apply the formula
	const totalScore = (
		((sumScoresV24 / 1.153) * 0.941 * 0.33) +
		((sumScoresV28 / 1.045) * 0.941 * 0.67)
	) * 12000;
	
	return totalScore;
}

function prepareCodes(data: any, ICD10Codes: any): PreparedCode[]{
	if(!data || !data.hasOwnProperty("hcCs") || !data.hcCs || data.hcCs.length === 0) {
		return [];
	}

	const codes: PreparedCode[] = [];

	data.hcCs.forEach((hcC: any) => {
		const model = hcC.engineResult.model;

		// Check if model is valid
		if (!["24", "28"].includes(model)) return;

		// Get codes
		hcC.hccScores.forEach((hccScore: any) => {
			const hccCode = hccScore.hccCode;
			const localCodes = hccScore.icD10Codes;
			const score = hccScore.score;

			// add codes to the list (if not already there)
			localCodes.forEach((code: any) => {
				const index = codes.findIndex((item) => item.code === code && item.model === model && item.hccCode === hccCode);
				if(index === -1){
					codes.push({
						name: getCodeName(code, ICD10Codes),
						code: code,
						hccCode: hccCode,
						model: model,
						score: score,
						hasImpact: true
					});
				}
			});
		});
	});

	return codes;
}

function getCodeName(code: string, ICD10Codes: any) {
	try {
		const index = ICD10Codes?.findIndex((item: any) => item.code === code || item.normalizedCode == code);
		if(index === -1) return code;
		return ICD10Codes[index].description;
	} catch (error) {
		return code;
	}
}

function findMissingCodes(preparedCodesA: PreparedCode[], preparedCodesB: PreparedCode[]){
	const missingCodes: PreparedCode[] = [];
	
	preparedCodesA.forEach((codeA) => {

		// Checking if the code exists in at least one of the objects in B
        const isFound = preparedCodesB.some((codeB) => codeB.code === codeA.code && codeB.model === codeA.model);

		if(isFound === false) {
			const missingCode = { ...codeA };

			// Checking if at least the HCC code exists in one of the objects in B
			const hasHccMatch = preparedCodesB.some((codeB) => codeB.hccCode === codeA.hccCode && codeB.model === codeA.model);
			if (hasHccMatch === true) {
				missingCode.hasImpact = false;
			}

			// // Check if HCC code exists in B
			// const checkHccCode = preparedCodesB.findIndex((codeB) => codeB.hccCode === codeA.hccCode && codeB.model === codeA.model);

			// if(checkHccCode >= 0){
			// 	missingCode.hasImpact = false;
			// }

			missingCodes.push(missingCode);
		}
	});

	return missingCodes;
}