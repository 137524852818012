import React, {FC} from "react";
import {Col, Container} from "lavaa";
import css from "../style.module.scss";
import {TreatmentPage} from "../Treatment/TreatmentPage.com";
import {HealthCard} from "../HealthCard/HealthCard.com";
import {PersonalInfoPage} from "../PersonalInfo/PersonalInfo.com";
import {QuestionnairePage} from "../Questionnaire/QuestionnairePage.com";
import {SurveysPage} from "../Surveys/Surveys.com";
import {RafScorePage} from "../RafScore/RafScore.com";
import {PatientPageHeader} from "../Header/patientPageHeader.com";

interface Props {
    activeTab: number;
    onCardClick?: (cardName: string) => void;
}

// Patient Page Content
const PatientPageContent: FC<Props> = React.memo((props) => {
    
    // Props
    const { activeTab, onCardClick } = props;

    return (
        <Container paddingLeft="1rem" paddingRight="1rem" className={css.Dashboard}>
            <Col paddingTop="2rem" className={css.DashboardContent}>
                <PatientPageHeader activeTab={activeTab}/>
                <Col paddingTop="1.5rem" paddingBottom="2rem" grow="1" className={css.PageContent}>
                    {
                        activeTab === 0 && (
                            <PersonalInfoPage onCardClick={onCardClick} />
                        )
                    }
                    {
                        activeTab === 1 && (
                            <HealthCard onCardClick={onCardClick}/>
                        )
                    }
                    {
                        activeTab === 2 && (
                            <TreatmentPage onCardClick={onCardClick}/>
                        )
                    }
                    {
                        activeTab === 3 && (
                            <QuestionnairePage onCardClick={onCardClick}/>
                        )
                    }
                    {
                        activeTab === 4 && (
                            <SurveysPage onCardClick={onCardClick}/>
                        )
                    }
                    {
                        activeTab === 5 && (
                            <RafScorePage onCardClick={onCardClick}/>
                        )
                    }
                </Col>
            </Col>
        </Container>
    )
});

export {PatientPageContent};
