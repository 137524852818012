import React, {FC, useEffect, useState} from "react";
import css from "../style.module.scss";
import {Col, Row, Text, Dropdown, iconsTypes, Button} from "lavaa";
import {useQuestionnaire} from "../../../../../Hooks/UseQuestionnaire";
import {useDemo} from "../../../../../Hooks/useDemo";
import {IModuleType} from "../../../../../Redux/Slices/Demo/Demo.data";
import {QuestionnaireFields} from "../QuestionnaireFields.com";
import {classNames} from "../../../../../Tools";

interface IProps {
    onSubmit: (filledInputs: {[k: string]: any}, confirmImmediately: boolean) => void
    inputs: any,
    onChange: (field: string, value: any) => void,
    loading: boolean
}

// Questionnaire Form Page
const QuestionnaireFormPage: FC<IProps> = React.memo((props) => {

    const { onSubmit, inputs, onChange, loading } = props;

    const [ filter, setFilter  ] = useState<string>("all");

    const [showSubmit, setShowSubmit] = useState<boolean>(false);

    const {questionnaireData} = useQuestionnaire();
    const { modules } = useDemo();

    const filterData: any = modules.map(( module: IModuleType ) => {
        return{
            id: module.ModuleUID,
            value: module.ModuleName
        }
    });
    filterData.push({id: 'all', value: 'Show All'});

    const selectedFilter = filterData.find((item: any) => item.id === filter);

    useEffect(() => {
        setShowSubmit(false);
    }, [questionnaireData]);

    useEffect(() => {
        validateFields();
    }, [inputs]);

    function selectFilterHandler(data: any){
        setFilter(data.id);
    }

    function validateFields(){
        const filledInputs: any = {};
        let canSubmit = false;

        Object.entries(inputs).map(([key, value]) => {
            if( value !== null && value !== undefined && value !== ''){
                // TODO - add input validation
                filledInputs[key] = value;
                canSubmit = true;
            }
        });

        setShowSubmit(canSubmit);
        return filledInputs;
    }

    function submit(confirmImmediately: boolean = false) {
        if(!showSubmit) return;

        const filledInputs: any = validateFields();

        if(Object.keys(filledInputs).length === 0) {
            setShowSubmit(false);
            return;
        }

        onSubmit(filledInputs, confirmImmediately);
    }


    const questionsCount = questionnaireData.length;

    return (
        <Col className={css.Wrapper}>
            <Row marginBottom="2rem" alignitems="center" justifycontent="space-between">
                <Row alignitems="center" justifycontent="space-between" className={css.Container} grow="1">
                    <Row alignitems="center">
                        <Col>
                            <Text size='x3' bold={true}>Questionnaire</Text>
                        </Col>
                        <Col marginLeft="1.75rem">
                            <Text size='x2'>{questionsCount} Question{questionsCount != 1 ? 's' : ''}</Text>
                        </Col>
                    </Row>
                    <Col className={css.Filter}>
                        <Dropdown icon={iconsTypes.filter} type="button-simplified" placeholder="Filter" hasSearch={true} data={ filterData } selected={selectedFilter} onSelect={selectFilterHandler}/>
                    </Col>
                </Row>
            </Row>
            {/*<ScrollContainer scrollRef={scrollRef}>*/}
                <Col className={classNames(css.FieldsWrapper, questionsCount > 0 && css._hasContent, questionsCount === 1 && css._hasOneField)}>
                    <QuestionnaireFields
                        questionnaireData={questionnaireData}
                        inputs={inputs}
                        onChange={onChange}
                        filter={filter}
                        key={filter}
                    />

                    {/* Submit Buttons */}
                    <Row justifycontent="end">
                    {
                        (questionsCount > 0) && (
                            <>
                                {/* Save results */}
                                <Row justifycontent="start">
                                    <Button
                                        text="Submit"
                                        type={showSubmit ? "primary" : "disable"}
                                        onClick={() => submit(true)}
                                        isLoading={loading}
                                    />
                                </Row>

                                {/* Review + Save */}
                                {
                                    (!loading) && (
                                        <Row justifycontent="start" paddingLeft="1rem">
                                            <Button
                                                text="Review + Save"
                                                type={showSubmit ? "secondary" : "disable"}
                                                onClick={() => submit(false)}
                                            />
                                        </Row>
                                    )
                                }
                            </>
                        )
                    }
                    </Row>
                </Col>
            {/*</ScrollContainer>*/}
        </Col>
    )
});

export {QuestionnaireFormPage};
