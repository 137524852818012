import React, {FC} from "react";
import {iconsTypes} from "lavaa";
import {HealthBox, HealthBoxIconColor} from "../../Cards/HealthBoxes/HealthBox.com";
import {useDemo} from "../../../../../Hooks/useDemo";

interface IProps {
    onCardClick?: (cardName: string) => void;
}

// A1C Card
const A1CCard: FC<IProps> = React.memo((props) => {

    const { onCardClick } = props;
    const {activeClient} = useDemo();

    const title = "Last A1C";

    const labResults = activeClient?.client?.LabResults ? activeClient?.client?.LabResults[0] : null;
    const value = labResults?.HBA1C ?? "-";

    let color:HealthBoxIconColor = "default";
    if(value >= 5.7){
        color = "danger";
    }
    else if(value < 5.7){
        color = "success";
    }

    return (
        <HealthBox
            title={title}
            icon={iconsTypes.labResults}
            value={value}
			iconColor={color}
            cardName="LastA1C"
            onCardClick={onCardClick}
        />
    )
});

export {A1CCard};
