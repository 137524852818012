import React, {FC, useEffect} from 'react';
import {Row, Col, Text, Button, iconsTypes, Icon, Cell, addSnackbar} from 'lavaa';
import css from './style.module.scss'
import {useDemo} from "../../../../../Hooks/useDemo";
import {useModules} from "../../../../../Hooks/UseModules";
import {useAccount} from "../../../../../Hooks/UseAccount";
import {useClinicalResearch} from "../../../../../Hooks/useClinicalResearch";
import {IClinicalResearchType} from "../../../../../Redux/Slices/ClinicalResearch/ClinicalResearch.data";

interface IProps {
}

const ClinicResearch: FC<IProps> = () => {

    const {activeClient} = useDemo();
    const {getModuleTemplate} = useModules();
    const {HMSProjectId} = useAccount();
    const {fetchClinicalResearches, clearClinicalResearch, clinicalResearch, updateClinicalResearchStatus} = useClinicalResearch();

    useEffect(() => {
        if(activeClient?.client?.SourceClientID === undefined) return;

        clearClinicalResearch();
        fetchClinicalResearches(activeClient?.client?.SourceClientID, HMSProjectId);
    }, [activeClient?.client?.SourceClientID, HMSProjectId]);

    if(!clinicalResearch || clinicalResearch.length === 0) {
        return (
            <Col paddingTop="0.5rem" paddingBottom="1rem" paddingLeft="1.25rem" paddingRight="1.25rem">
                <Text size="x3" className={css.Empty}>No Clinical Trial Data Available</Text>
            </Col>
        )
    }

    function changeStatusHandler(item: IClinicalResearchType) {
        updateClinicalResearchStatus(item.ModuleUID, item.ClientID, item.ProjectUID)
        addSnackbar('success', 'Success!', 'Client was approved for clinical research');
    }

    return (
        <Col paddingTop="0.5rem" paddingBottom="1rem" paddingLeft="1.25rem" paddingRight="1.25rem">
            {
                clinicalResearch.map((item: IClinicalResearchType, i: number) => {
                    const module = getModuleTemplate(item.ModuleUID);
                    if(!module) return null;
                    return (
                        <Row className={css.Item} alignitems="center" justifycontent="space-between" key={i}>
                            <Col>
                                <Text size="x3">
                                    {module.name}
                                </Text>
                            </Col>
                            <Col className={css.Info} alignitems="end" shrink="0">
                                {
                                    item.IsApproved ? (
                                        <Row className={css.Approved} alignitems="center">
                                            <Cell className={css.ApprovedIcon}>
                                                <Icon name={iconsTypes.checkmark} size="x1"/>
                                            </Cell>
                                            <Text size="x2" text="Approved" className={css.ApprovedText} bold={true}/>
                                        </Row>
                                    ) : (
                                        <Button type="secondary" text={"Approve"} onClick={() => changeStatusHandler(item)}/>
                                    )
                                }
                            </Col>
                        </Row>
                    );
                })
            }
        </Col>
    )
};

export {ClinicResearch};
