import React, {FC, useContext, useEffect, useState} from "react";
import {Container, Grid, Splitter} from "lavaa";
import {ModuleSettingsModal} from "../../Custom/Modals/PersonEdit/PersonEditModal.component";
import {PatientLabResultModal} from "../../Custom/Modals/PatientLabResultModal/PatientLabResultModal.component";
import {AppCtx} from "../../../Context/App.context";
import {PatientPageContent} from "./Content/PatientPageContent";
import {PatientPageMenu} from "./Menu/PatientPageMenu.com";
import {DiagnoseInfoModal} from "../../Custom/Modals/DiagnoseInfoModal/DiagnoseInfoModal.component";
import css from "./style.module.scss";
import {MenuHealthBoxesModal} from "../../Custom/Modals/MenuHealthBoxes/MenuHealthBoxesModal.com";
import {ReportClientInfo} from "../../Custom/Modals/ReportClientInfo/ReportClientInfo.com";

const mapCardNameToTab: Record<string, number> = {
    'OperationalWorryScore': 0,
    'ClinicalTrial': 0,
    'BMI': 0,
    'Pulse': 0,
    'BloodPressure': 0,
    'Height': 0,
    'Weight': 0,
    'Waist': 0,
    'Race': 0,
    'ActivityLevel': 0,
    'StressLevel': 0,
    'LastA1C': 1,
    'LastGFR': 1,
    'BodyTemperature': 1,
    'CO2Saturation': 1,
    'PainLevel': 1,
    'Respiration': 1,
    'LastLDL': 1,
    'HighRiskDiagnosis': 2,
    'RafScore': 5
};

// Patient Page Desktop
const PatientPageDesktop: FC<any> = React.memo(() => {

    // Contexts
    const { performanceNow } = useContext(AppCtx);

    //States
    const [activeTab, setActiveTab] = useState(0);
    const [screenSize, setScreenSize] = useState<number>(0);

    // On Card Click
    const onCardClick = (cardName: string) => {
        const tabId: number = mapCardNameToTab[cardName];
        setActiveTab(tabId);
    }

    useEffect(() => {
        window.addEventListener('resize', checkScreenSize);

        function checkScreenSize() {
            setScreenSize(window.innerWidth);
        }

        checkScreenSize();

        return () => {
            window.removeEventListener('resize', checkScreenSize);
        }
    }, []);

    let collapsible= [0];
    let paneSizes = ['15%', '85%'];
    let minSizes: any = [300, 900];
    let splitterName = 'BaseLayout';

    if(screenSize <= 1450) {
        minSizes = [300, 850];
    }

    return (
        <Container className={css.Container}>

            <Splitter resetLayoutKey={performanceNow} direction="vertical" collapsible={collapsible} paneSizes={paneSizes} minSizes={minSizes} splitterSize="10px" collapsedSplitterSize="20px" name={splitterName} grow="1">
                <Grid style={{overflow: 'auto'}}>
                    {/* Menu */}
                    <PatientPageMenu activeTab={activeTab} setActiveTab={setActiveTab}/>
                </Grid>
                <Grid>
                    {/* Pages */}
                    <PatientPageContent activeTab={activeTab} onCardClick={onCardClick} />
                </Grid>
            </Splitter>


            {/*  Person Edit Modal */}
            <ModuleSettingsModal/>

            {/* Modals */}
            <PatientLabResultModal/>
            <DiagnoseInfoModal/>
            <ReportClientInfo/>

            {/*  Menu Health Boxes Modal */}
            <MenuHealthBoxesModal/>

        </Container>
    )
});

export {PatientPageDesktop};
