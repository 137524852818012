import React, {FC} from "react";
import {iconsTypes} from "lavaa";
import {HealthBox, HealthBoxIconColor} from "../../Cards/HealthBoxes/HealthBox.com";
import {useDemo} from "../../../../../Hooks/useDemo";

interface IProps {
    onCardClick?: (cardName: string) => void;
}

// Pulse Card
const PulseCard: FC<IProps> = React.memo((props) => {

    const { onCardClick } = props;
    const {activeClient} = useDemo();

    const title = "Pulse";

    const personalRecords = activeClient?.client?.PersonalRecords ? activeClient?.client?.PersonalRecords[0] : null;
    const value = personalRecords?.Pulse ?? "–";

    let color:HealthBoxIconColor = "default";
    if(value < 60){
        color = "danger";
    }
    else if(value >= 60 && value <= 100){
        color = "success";
    }
    else if(value > 100){
        color = "danger";
    }

    return (
        <HealthBox
            title={title}
            icon={iconsTypes.pulse}
            value={value}
			iconColor={color}
            cardName="Pulse"
            onCardClick={onCardClick}
        />
    )
});

export {PulseCard};
