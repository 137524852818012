import React, {FC} from "react";
import {iconsTypes} from "lavaa";
import {HealthBox, HealthBoxIconColor} from "../../Cards/HealthBoxes/HealthBox.com";
import {useClients} from "../../../../../Hooks/UseClients";
import { useStatic } from "../../../../../Hooks/UseStatic";
import { prepareData } from "../../RafScore/Compare/util";

interface IProps {
    onCardClick?: (cardName: string) => void;
}

// Raf Score Card
const RafScoreCard: FC<IProps> = React.memo((props) => {
    
    // Props
    const { onCardClick } = props;

    // Hooks
    const { clientScores } = useClients();
    const { ICD10Codes } = useStatic();

    // Get Missed Codes
    const missedCodes = prepareData(clientScores, 'emrhcc', ['billingHCC'], ICD10Codes);
    const suggestedCodes = prepareData(clientScores, 'lavaaHCC', ['emrhcc', 'billingHCC'], ICD10Codes);
    const isRafScoreEmpty = clientScores === '' || Object.keys(clientScores).length === 0;
    console.log('ICD10Codes', ICD10Codes);
    
    const title = "Raf Score";
    let icon;
    let color: HealthBoxIconColor;

    const current = clientScores?.emrhcc?.score?.toFixed(1) ?? '-';

    // Success Icon
    if (missedCodes.cost === 0 && suggestedCodes.cost === 0 && !isRafScoreEmpty) {
        icon = iconsTypes.checkCircleOutline;
        color = "success";
    }

    // Info Icon
    else if (missedCodes.cost === 0 && suggestedCodes.cost > 0 && !isRafScoreEmpty) {
        icon = iconsTypes.magnifierCheck;
        color = "info";
    }

    // Danger Icon
    else if(missedCodes.cost > 0 && !isRafScoreEmpty) {
        icon = iconsTypes.warningCircle;
        color = "danger";
    }

    // Default Icon
    else {
        icon = iconsTypes.checkCircleOutline;
        color = "default";
    }

    return (
        <HealthBox
            title={title}
            icon={icon}
            value={current}
			iconColor={color}
            cardName="RafScore"
            onCardClick={onCardClick}
        />
    )
});

export {RafScoreCard};
